import { gql } from '@apollo/client';

export const CORE_PROCESS_FIELDS = gql`
    fragment CoreProcessFields on ProcessType {
        id
        name
        description
        startTime
        endTime
        lastUpdated
        qualityScore
        qualityMessages
        calculationDate
        calculationNeeded
        
        topic {
            id
            name
            team {
                id
                name
            }
            
            mediaSet {
                id
                name
            }

            userPermissions
        }
    }
`;
            
export const CORE_OWNER_FIELDS = gql`
    fragment CoreOwnerFields on UserType {
        id
        username
        firstName
        lastName
        email
    }
`;

export const CORE_TOPIC_FIELDS = gql`
    ${CORE_OWNER_FIELDS}
    fragment CoreTopicFields on TopicType {
        id
        name
        description
        numProcesses
        permissionMode
        userPermissions
        imageB64
        needsCalculation
        teamDataStandardsEnabled
        organizationDataStandardsEnabled
        
        owner {
            ...CoreOwnerFields
        }

        team {
            id
            name
            userPermissions
        }
    }
`;

export const CORE_PARAMETER_FIELDS = gql`
    fragment CoreParameterFields on ParameterType {
        id
        name
        value
        units
        description
        calculator {
            id
            name
        }
        dataStandard {
            id
            name
            level
        }
    }
`;


export const CORE_METADATA_FIELDS = gql`
    fragment CoreMetadataFields on MetaDataType {
        id
        name
        value
        description
        type
        valueString
        valueMedia {
            id
            name
        }
        dataStandard {
            id
            name
            level
        }
    }
`;

export const CORE_VARIABLE_FIELDS = gql`
    fragment CoreVariableFields on VariableType {
        id
        name
        description
        units
        vartype
        
        calculator {
            id
            name
        }
        dataStandard {
            id
            name
            level
        }
    }
`;

export const CORE_VARIABLE_FIELDS_WITH_DATA = gql`
    ${CORE_VARIABLE_FIELDS}
    fragment CoreVariableFields on VariableType {
        ...CoreVariableFields
        time
        data
        processTime

    }
`;

export const CORE_NOTE_FIELDS = gql`
    fragment CoreNoteFields on NoteType {
        id
        note
        processTime
        user {
            id
            username
        }
    }
`;

export const CORE_CALCULATOR_FIELDS = gql`
    fragment CoreCalculatorFields on CalculatorType {
        id
        order
        name
        description
        calcfunction
        expression
        level
        message
        enabled
        calculatorSave {
            id
            calculatorSet {
                id
                name
                order
            }
        }
    }
`;

export const CORE_CALCULATOR_SAVE_FIELDS = gql`
    fragment CoreCalculatorSaveFields on CalculatorSaveType {
        id
        order
        name
        description
        calcfunction
        expression
        enabled
        
    }
`;

export const CORE_TOPIC_USER_CONFIGS_FIELDS = gql`
    fragment CoreTopicUserConfigsFields on TopicUserConfigsType {
        id
        processTableMetaData
        processTableParameters
        processTableFilterProcessNameContains
        processTableFilterMetaDataNames
        processTableFilterMetaDataConditions
        processTableFilterMetaDataValues
        processTableFilterDqsMin
        processTableFilterDqsMax
        processTableDisplayMetaData
        processTableDisplayParameters
        processTableDisplayDataQuality
        processTableDisplayStartTime
        processTableDisplayDescription
        processTableDisplayLastModified
    }
`;


export const EXTENDED_PROCESS_FIELDS = gql`
    ${CORE_PROCESS_FIELDS}
    ${CORE_METADATA_FIELDS}
    ${CORE_PARAMETER_FIELDS}
    ${CORE_VARIABLE_FIELDS}
    ${CORE_NOTE_FIELDS}
    ${CORE_CALCULATOR_FIELDS}
    
    fragment ExtendedProcessFields on ProcessType {
        ...CoreProcessFields

        metadataSet {
            ...CoreMetadataFields
        }
        
        parameterSet {
            ...CoreParameterFields
        }

        variableSet {
            ...CoreVariableFields
        }

        noteSet {
            ...CoreNoteFields
        }
        
        calculatorSet {
            ...CoreCalculatorFields
        }
    }
`;

export const EXTENDED_PROCESS_FIELDS_WITH_DATA = gql`
    ${CORE_PROCESS_FIELDS}
    ${CORE_METADATA_FIELDS}
    ${CORE_PARAMETER_FIELDS}
    ${CORE_VARIABLE_FIELDS}
    ${CORE_NOTE_FIELDS}
    ${CORE_CALCULATOR_FIELDS}
    
    fragment ExtendedProcessFieldsWithData on ProcessType {
        ...CoreProcessFields

        metadataSet {
            ...CoreMetadataFields
        }
        
        parameterSet {
            ...CoreParameterFields
        }

        variableSet {
            ...CoreVariableFields
            time
            data
            processTime
        }

        noteSet {
            ...CoreNoteFields
        }
            
        calculatorSet {
            ...CoreCalculatorFields
        }
    }
`;



export const CORE_TEAM_FIELDS = gql`
    fragment CoreTeamFields on TeamType {
        id
        name
        description
        userPermissions
        owner {
            id
            username
        }
    }
`;



export const CORE_BACKGROUND_TASK_FIELDS = gql`
    fragment CoreBackgroundTaskFields on BackgroundTaskType {
        id
        taskName
        progress
        status
        outputMessage
        outputParameters
        creationDate
        lastUpdated
        runDuration
        statusMessage
    }
`;

export const CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS = gql`
    fragment CoreProcessExplorerViewPageFields on ViewPageType {
        id
        name
        numplots
        xaxis
        xaxisMin
        xaxisMax
        xaxisUnits
        yaxis
        yaxisMin
        yaxisMax
        yaxisUnits
        lineWidth
        plotMode
        showLegend
        legendPosition
    }
`;

export const CORE_ANNOTATION_FIELDS = gql`
    fragment CoreAnnotationFields on ProcessExplorerAnnotationType {
        id
        label
        xAxis
        xValue
        xUnits
        color
    }
`;

// for both process explorer and kpi analysis
export const CORE_VIEW_FIELDS = gql`
    
    ${CORE_ANNOTATION_FIELDS}

    fragment CoreViewFields on ProcessExplorerViewType {
        id
        notes
        groupByMetaData
        metaDataForGrouping
        processexplorerannotationSet {
            ...CoreAnnotationFields
        }
        processesForDisplay {
            id
        }
        processcolorSet {
            id
            process {
                id
            }
            color
        }
    
    }
`;

export const CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS = gql`
    fragment CoreKpiAnalysisViewPageFields on ViewPageType {
        id
        name
        kpiAnalysisNumplots
        kpiAnalysisXaxis
        kpiAnalysisYaxis
    }
`;

export const EXTENDED_VIEW_PAGE_FIELDS = gql`
    ${CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS}
    ${CORE_KPI_ANALYSIS_VIEW_PAGE_FIELDS}
    fragment ExtendedViewPageFields on ViewPageType {
        ...CoreProcessExplorerViewPageFields
        ...CoreKpiAnalysisViewPageFields
    }
`;

export const CORE_KPI_FIELDS = gql`
    fragment CoreKPIFields on KPIType {
        id
        name
        x
        y
        f
        units
        interpXValue
    }
`;

export const CORE_KPI_TABLE_FIELDS = gql`
    fragment CoreKPITableFields on KPITableType {
        values
        columns
        columnIds
        columnUnits
        index
        json
        processIds
    }
`;


export const CORE_ANALYSIS_FIELDS = gql`
    ${CORE_TEAM_FIELDS}
    ${CORE_VIEW_FIELDS}
    ${EXTENDED_VIEW_PAGE_FIELDS}
    ${CORE_PROCESS_FIELDS}
    fragment CoreAnalysisFields on AnalysisType {
        id
        name
        description
        userPermissions
        nProcesses
        
        owner {
            id
            username
        }
        
        team {
            ...CoreTeamFields
        }

        processes {
            ...CoreProcessFields
        }
        
        kpiAnalysisView {
            ...CoreViewFields
            selectedViewPage {
                ...ExtendedViewPageFields
            }
        }
        
        processExplorerView {
            ...CoreViewFields
            selectedViewPage {
                ...ExtendedViewPageFields
            }
        }
        
        statmodelSet {
            id
        }

        kpiSet {
            id
        }
    }
`;

export const CORE_USER_PROFILE_FIELDS = gql`
    fragment CoreUserProfileFields on UserProfileType {
        id
        avatarB64
        darkMode
        admin
        leftMenuCollapsed
        leftMenuOpenKeys
        collectionsPageViewMode
        selectedTeam {
            id
            name
            owner {
                id
            }
        }
        selectedAnalysis {
            id
            name
        }
        user {
            id
        }
        organization {
            id
            aiAssistantEnabled
        }
    }
`;

export const CORE_CALC_INTERFACE_FIELDS = gql`
    fragment CalcInterfaceFields on CalcInterfaceType {
        id
        name
        units
        description
        interfaceType
        direction
        required
        altNames
        valueFloat
        valueFloatMin
        valueFloatMax
        valueChar
        status
    }
`;

export const CORE_CALC_SAVE_INTERFACE_FIELDS = gql`
    fragment CalcSaveInterfaceFields on CalcSaveInterfaceType {
        id
        name
        units
        description
        interfaceType
        direction
        required
        altNames
        valueFloat
        valueFloatMin
        valueFloatMax
        valueChar
        status
    }
`;

export const CORE_CALCULATOR_SET_FIELDS = gql`
    fragment CoreCalculatorSetFields on CalculatorSetType {
        id
        name
        order
        description
        enabled
    }
`;

export const CORE_DATA_STANDARD_FIELDS = gql`
    fragment CoreDataStandardFields on DataStandardType {
        id
        name
        description
        enabled
        level
        required
        units
        type
        valueLb
        valueUb
        minDataPoints
        metaDataRegex
        downsample
        percentChange
        deadband
        minDataFreq
        maxDataFreq
    }
`;

export const CORE_COMPOUND_FIELDS = gql`
    fragment CoreCompoundFields on CompoundType {
        id
        name
        formula
        casNumber
    }
`;


export const CORE_MEDIA_FIELDS = gql`
    fragment CoreMediaFields on MediaType {
        id
        name
        description
    }
`;

export const CORE_MIXTURE_FIELDS = gql`
    fragment CoreMixtureFields on MixtureType {
        id
        name
        description
    }
`;

export const CORE_UNDEFINED_INGREDIENT_FIELDS = gql`
    fragment CoreUndefinedIngredientFields on UndefinedIngredientType {
        id
        name
        description
    }
`;

export const CORE_MEDIA_ACTION_FIELDS = gql`
    ${CORE_COMPOUND_FIELDS}
    ${CORE_MIXTURE_FIELDS}
    ${CORE_UNDEFINED_INGREDIENT_FIELDS}
    fragment CoreMediaActionFields on MediaActionType {
        id
        order
        description
        compound {
            ...CoreCompoundFields
        }
        undefinedIngredient {
            ...CoreUndefinedIngredientFields
        }
        mixture {
            ...CoreMixtureFields
        }
        amount
        amountUnits
    }
`;

export const CORE_IMPORT_FIELDS = gql`
    fragment CoreImportFields on ImportType {
        id
        user {
            id
            username
        }
        importType
        creationDate
        topic {
            id
            name
        }
        files {
            id
            name
        }
        processes {
            id
            name
        }
        backgroundTask {
            id
            status
            outputMessage
            outputParameters
        }
    }
`;