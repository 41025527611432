import { useState, useEffect, useMemo , useRef} from 'react';
import { Select, Card, Space, Skeleton, Popover, Button, Slider, Form, AutoComplete, InputNumber, Modal, message, Table , Spin} from 'antd';
import Plot from 'react-plotly.js';
import { useQuery, useLazyQuery, useMutation, gql, useReactiveVar } from '@apollo/client';
import useWindowDimensions from '../useWindowDimensions';
import {  SettingOutlined , CloseOutlined, InfoCircleOutlined, MessageOutlined } from '@ant-design/icons';
import { processExplorerSelectedViewPageVar } from '../../Apollo';
import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';
import { createOptimisticResponse } from '../createOptimisticResponse';
import {CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS} from '../fragments';
import { processesLoading } from '../../Apollo';
import Plotly from 'plotly.js-dist';

const { Option } = Select;

const UPDATE_VIEW_PAGE = gql`
    ${CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS }
    mutation updateViewPage ($input: UpdateViewPageInputType!) {
        updateViewPage( input:$input) {
            viewPage {
                ...CoreProcessExplorerViewPageFields
            }
        }
    }
`;

const GET_UNIQUE_VARS = gql`
  query uniqueVars($analysisId:ID!) {

    selectedProcesses (analysisId:$analysisId) {
        id
        name
    }

    uniqueVars(analysisId:$analysisId)

    userProfile {
      id
      darkMode
    }
  }
`

const GET_VARIABLE_DATA = gql`
  query VariableData($input: VariableDataInputType!) { 
    variableData(input:$input) {
        id
        data
        units
    }
  }
`

interface ProcessExplorerPlotInputType {
  id: number
  analysis:any
  processData: any
  loading: any
  setSelectedSidebarProcess?: Function
  processFetchingComplete: boolean
}

function ProcessExplorerPlot(props: ProcessExplorerPlotInputType) {

  /***************************************** QUERIES & MUTATIONS ************************************************************* */

  const { loading: queryLoading, error: queryError, data: queryData } = useQuery(GET_UNIQUE_VARS, 
    { 
      variables: { analysisId: props.analysis?.id },
      //fetchPolicy: 'cache-and-network',
    }
  );

  // use the useLazyQuery hook to get the data for variableData
  const [getVariableData, { loading: variableDataLoading, error: variableDataError, data: variableDataData }] = useLazyQuery(GET_VARIABLE_DATA);

  const [updateViewPage , { loading: mutationLoading }] = useMutation(UPDATE_VIEW_PAGE, {
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: (data) => {
      //message.success('View updated');
    },

  });
   
  /***************************************** STATES ************************************************************* */

  // plotData is the variable to hold data for the plot - set it to type any
  const [plotData, setPlotData] = useState<any>([])

  const [availableYUnits, setAvailableYUnits] = useState<any>([])
  const [yUnitsAutoComplete, setYUnitsAutoComplete] = useState("")

  const [previousYAxisName, setPreviousYAxisName] = useState("")
  const [previousYAxisUnits, setPreviousYAxisUnits] = useState("")

  const [plotWidth, setPlotWidth] = useState("32%")
  const [plotHeight, setPlotHeight] = useState(400)
  const [plotFractiom, setPlotFraction] = useState(0.9)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const [modalDimensions, setModalDimensions] = useState({ width: 400, height: 300 });

  const [plotShapes, setPlotShapes] = useState<any>([])   // holds the annotation line shapes for the plot
  const [scatterData, setScatterData] = useState<any>([]) // data for the red-line annotations, so that a data callout is shown when hovering over the line

  const [axisTitleSize, setAxisTitleSize] = useState(12)
  const [selectedPoints, setSelectedPoints] = useState<any>([]) // holds the selected points in the plot for e.g. outlier marking (not implemented yet)

  const [legendXPosition, setLegendXPosition] = useState(0.99)
  const [legendYPosition, setLegendYPosition] = useState(0.99)
  const [legendYAnchor, setLegendYAnchor] = useState<any>("top")
  const [legendXAnchor, setLegendXAnchor] = useState<any>("right")

  const processExplorerSelectedViewPage = useReactiveVar(processExplorerSelectedViewPageVar);
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  const processesLoadingVar = useReactiveVar(processesLoading);

  /***************************************** CONSTANTS ************************************************************* */

  const viewPage = props?.analysis?.processExplorerView?.viewpageSet.find((viewpage: any) => viewpage.id === processExplorerSelectedViewPage)

  
  //const xAxisUnits = processExplorerView?.selectedViewPage.xaxisUnits
  //const yAxisUnits = viewPage?.yaxisUnits[props.id]

  const xAxisName = viewPage?.xaxis
  const xAxisUnits = viewPage?.xaxisUnits

  const lineWidth = viewPage?.lineWidth[props.id]

  const { height, width } = useWindowDimensions();

  // set the plotMode

  let plotMode = viewPage?.plotMode[props.id]
  if (plotMode == null) {
    plotMode = "lines"
  }

  const verbose = false

  /***************************************** FUNCTIONS ************************************************************* */


  // Define the correct type for the ref
  const plotRef = useRef<any>(null); // Use `any` to bypass strict typing issues

  // Function to export the graph as a base64 image
  const exportPlotAsBase64 = async () => {
      if (plotRef.current && plotRef.current.el) {
          const plotNode = plotRef.current.el; // Access the DOM element containing the Plotly instance
          const base64Image = await Plotly.toImage(plotNode, {
              format: 'png',
              width: 800,
              height: 600,
          });
          console.log("Base64 Image:", base64Image);
          return base64Image.split(',')[1]; // Strip the 'data:image/png;base64,' prefix
      } else {
          console.error("Plot ref is not available.");
          return null;
        }
      };

  // function to handle onChange of the plot mode selection
  function onChangePlotMode(plotModeName: string) {

    let plotModeVector: any = [...viewPage?.plotMode]  // create a copy to avoid readonly errors - initialize the plotMode vector using props
    plotModeVector[props.id] = plotModeName   // write the selected plotMode to the correct position of the vector


    // create a variable for mutation input
    let input = {
      id: viewPage.id,
      plotMode: plotModeVector,
    }

    const optimisticResponse = createOptimisticResponse(
      client,
      CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
      viewPage.id,
      { plotMode: plotModeVector },
      "CoreProcessExplorerViewPageFields"
    )

    // perform the mutation to update the value of the Y-Axis in the view
    updateViewPage({
      variables: { input: input  } ,
      optimisticResponse: {
          updateViewPage: {
              __typename: 'Mutation',
              viewPage: {
                  __typename: 'ViewPageType',
                  ...optimisticResponse
              }
          }
      }
  });

  }

  function onChangeYAxis(newYAxisName: string) {

    if (verbose) {
      console.log("################################################")
      console.log("Changing the y-axis to: ", newYAxisName)
    }
    

    // set the previous y-axis name
    setPreviousYAxisName(viewPage.yaxis[props.id])
    setPreviousYAxisUnits(viewPage.yaxisUnits[props.id])

    let yAxis: any = [...viewPage?.yaxis]  // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yAxis[props.id] = newYAxisName   // write the selected yaxis name to the correct position of the vector

    let yMin = [...viewPage?.yaxisMin] // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yMin[props.id] = null

    let yMax = [...viewPage?.yaxisMax] // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yMax[props.id] = null

    
    const availableYUnitsLocal = getAvailableYUnits(newYAxisName)
    let yaxisUnits = [...viewPage?.yaxisUnits]
    yaxisUnits[props.id] = availableYUnitsLocal[0]
    //yaxisUnits[props.id] = ""

    // set the units to the first available unit

    // create a variable for mutation input
    let input = {
      id: viewPage.id,
      yaxis: yAxis,
      // reset the min and max
      yaxisMin: yMin,
      yaxisMax: yMax,
      yaxisUnits: yaxisUnits
    }

    const optimisticResponse = createOptimisticResponse(
      client,
      CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
      viewPage.id,
      { yaxis: yAxis, yaxisMin: yMin, yaxisMax: yMax , yaxisUnits: yaxisUnits },
      "CoreProcessExplorerViewPageFields"
    )

    // perform the mutation to update the value of the Y-Axis in the view
    updateViewPage({
      variables: { input: input  } ,
      optimisticResponse: {
          updateViewPage: {
              __typename: 'Mutation',
              viewPage: {
                  __typename: 'ViewPageType',
                  ...optimisticResponse
              }
          }
      }
    });

  }

  function onChangeYUnits(value: any) {
    setYUnitsAutoComplete(value)
  }

  function onSubmitChangeYUnits(value: any) {

    //setPreviousYAxisUnits(viewPage.yaxisUnits[props.id])
    //setPreviousYAxisName(viewPage.yaxis[props.id])

    let yUnits: any = [...viewPage?.yaxisUnits] // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yUnits[props.id] = yUnitsAutoComplete   // write the selected yaxis units to the correct position of the vector

    // reset min and max
    let yMin = [...viewPage?.yaxisMin] // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yMin[props.id] = null

    let yMax = [...viewPage?.yaxisMax] // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yMax[props.id] = null

    let input = {
      id: viewPage.id,
      yaxisUnits: yUnits,
      yaxisMin: yMin,
      yaxisMax: yMax,
    }

    const optimisticResponse = createOptimisticResponse(
      client,
      CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
      viewPage.id,
      { yaxisUnits: yUnits , yaxisMin: yMin, yaxisMax: yMax },
      "CoreProcessExplorerViewPageFields"
    )

    // perform the mutation
    updateViewPage({
      variables: { input: input  } ,
      optimisticResponse: {
          updateViewPage: {
              __typename: 'Mutation',
              viewPage: {
                  __typename: 'ViewPageType',
                  ...optimisticResponse
              }
          }
      }
    });

  }

  function getAvailableYUnits(variableName: string) {
    let key: any = ''
    let process: any = {}
    let availableYUnitsLocal: any = []

    // loop over the processes in the view
    for ([key, process] of Object.entries(props.processData)) {
      
      // if queryData.selectedProcesses includes process.id
      if (queryData?.selectedProcesses.find((x: any) => x.id == process.id)) {

        // find the variable in the process that has the same name as the needed y-variable
        let variable = process.variableSet.find((v: any) => v.name == variableName)

        // if the variable is not found, skip to the next process
        if (variable == null) {
          continue
        }

        // if the variable is found, add the units to the list of available units - the list shoud be unique
        if (variable?.units != null) {
          if (!availableYUnitsLocal.includes(variable.units)) {
            availableYUnitsLocal.push(variable.units)
          }
        }

      }

    }

    return availableYUnitsLocal
  }

  
  function setFirstAvailableYUnits(variableName: string) {

    const availableYUnitsLocal = getAvailableYUnits(variableName)

    if (availableYUnitsLocal.length > 0) {
      
      setYUnitsAutoComplete(availableYUnitsLocal[0])

      let yUnits: any = [...viewPage?.yaxisUnits] // create a copy to avoid readonly errors - initialize the y-axis vector using props
      yUnits[props.id] = availableYUnitsLocal[0]   // write the selected yaxis units to the correct position of the vector

      let input = {
        id: viewPage.id,
        yaxisUnits: yUnits,
      }

      const optimisticResponse = createOptimisticResponse(
        client,
        CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
        viewPage.id,
        { yaxisUnits: yUnits },
        "CoreProcessExplorerViewPageFields"
      )

      updateViewPage({
        variables: { input: input  } ,
        optimisticResponse: {
            updateViewPage: {
                __typename: 'Mutation',
                viewPage: {
                    __typename: 'ViewPageType',
                    ...optimisticResponse
                }
            }
        }
      });
    }
  }


  function onChangeYAxisMin(value: any) {
    let yMin: any = [...viewPage?.yaxisMin] // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yMin[props.id] = value   // write the selected yaxis units to the correct position of the vector

    let input = {
      id: viewPage.id,
      yaxisMin: yMin,
    }

    const optimisticResponse = createOptimisticResponse(
      client,
      CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
      viewPage.id,
      { yaxisMin: yMin },
      "CoreProcessExplorerViewPageFields"
    )

    updateViewPage({
      variables: { input: input  } ,
      optimisticResponse: {
          updateViewPage: {
              __typename: 'Mutation',
              viewPage: {
                  __typename: 'ViewPageType',
                  ...optimisticResponse
              }
          }
      }
    });
  }

  function onChangeYAxisMax(value: any) {
    let yMax: any = [...viewPage?.yaxisMax] // create a copy to avoid readonly errors - initialize the y-axis vector using props
    yMax[props.id] = value   // write the selected yaxis units to the correct position of the vector

    let input = {
      id: viewPage.id,
      yaxisMax: yMax,
    }

    const optimisticResponse = createOptimisticResponse(
      client,
      CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
      viewPage.id,
      { yaxisMax: yMax },
      "CoreProcessExplorerViewPageFields"
    )

    updateViewPage({
      variables: { input: input  } ,
      optimisticResponse: {
          updateViewPage: {
              __typename: 'Mutation',
              viewPage: {
                  __typename: 'ViewPageType',
                  ...optimisticResponse
              }
          }
      }
    });
  }

  function onChangeLineWidth(value: any) {

    // create a variable for mutation input
    let lw: any = [...viewPage?.lineWidth]  // create a copy to avoid readonly errors - initialize the y-axis vector using props
    lw[props.id] = value   // write the selected yaxis name to the correct position of the vector

    let input = {
      id: viewPage.id,
      lineWidth: lw,
    }

    const optimisticResponse = createOptimisticResponse(
      client,
      CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
      viewPage.id,
      { lineWidth: lw },
      "CoreProcessExplorerViewPageFields"
    )

    // perform the mutation to update the value of the Y-Axis in the view
    updateViewPage({
      variables: { input: input  } ,
      optimisticResponse: {
          updateViewPage: {
              __typename: 'Mutation',
              viewPage: {
                  __typename: 'ViewPageType',
                  ...optimisticResponse
              }
          }
      }
    });
  }

  function onChangeLegendPosition(value: any) {
    
    let legendPosition:any = [...viewPage?.legendPosition]  // create a copy to avoid readonly errors - initialize the legendPosition vector using props
    legendPosition[props.id] = value   // write the selected legend position to the correct position of the vector

    // create a variable for mutation input
    let input = {
      id: viewPage.id,
      legendPosition: legendPosition,
    }

    const optimisticResponse = createOptimisticResponse(
      client,
      CORE_PROCESS_EXPLORER_VIEW_PAGE_FIELDS,
      viewPage.id,
      { legendPosition: legendPosition },
      "CoreProcessExplorerViewPageFields"
    )

      updateViewPage({
        variables: { input: input  } ,
        optimisticResponse: {
            updateViewPage: {
                __typename: 'Mutation',
                viewPage: {
                    __typename: 'ViewPageType',
                    ...optimisticResponse
                }
            }
        }
      });
    
  }

  function handleModalCancel() {
    setIsModalVisible(false)
  }

  function interp1(yDataTime: Date[], yData: number[], xDataTime: Date[]): (number | null)[] {
    // Ensure all entries in yDataTime and xDataTime are Date objects
    
    const xDates = xDataTime.map(t => (t instanceof Date ? t : new Date(t)));
    const yDates = yDataTime.map(t => (t instanceof Date ? t : new Date(t)));

    return xDates.map(xTime => {

        // Ensure the interpolation is within the bounds of yDates
        if (xTime < yDates[0] || xTime > yDates[yDates.length - 1]) {
            return null;  // xTime is out of the range of provided yDataTime
        }

        let before = 0; // Initialize to the first index
        let after = yDates.length - 1; // Initialize to the last index

        // Find the first entry in yDates that is after xTime
        for (let i = 0; i < yDates.length; i++) {
            if (yDates[i].getTime() > xTime.getTime()) {
                after = i;
                before = Math.max(i - 1, 0); // Ensure 'before' is never less than 0
                break;
            }
        }

        // If xTime matches yDates[before] exactly, return the corresponding yData
        if (yDates[before].getTime() === xTime.getTime()) {
            return yData[before];
        }

        // If 'before' and 'after' are the same, it means xTime is beyond the last yDate
        if (before === after) {
            return null;  // Since we check range earlier, this condition may never be hit
        }

        // Linear interpolation calculation
        const timeDifference = yDates[after].getTime() - yDates[before].getTime();
        const slope = (yData[after] - yData[before]) / timeDifference;
        const interpolatedValue = yData[before] + slope * (xTime.getTime() - yDates[before].getTime());
        return interpolatedValue;
        
    });
  }

  // can use this to remember position of cursor and adding of lines
  function plotOnHover(value: any) {
    // set the position of the red line
    //setRedLineXPosition(value.points[0].x)
  }

  // function to handle onClick of the plot
  function plotOnClick(value: any) {

    // get the processId of the clicked process 
    // customData works for grouped plots (grouped by metadata)
    let clickedProcessId = value.points[0].customdata?.processId
    
    //console.log("Value: ", value)
    //console.log("Clicked process id: ", clickedProcessId)

    // if undefined (i.e. for non-grouped plots), use the id of the process
    if (clickedProcessId == undefined) {
      clickedProcessId = value.points[0].data.id
    }

    if (props.setSelectedSidebarProcess) {
      props.setSelectedSidebarProcess(clickedProcessId)
      //setRedLineXPosition(value.points[0].x)
    }
  }

  // function to handle the info modal
  function showInfoModal() {
    setInfoModalVisible(true)
  }

  // close
  function handleInfoModalCancel() {
    setInfoModalVisible(false)
  }



  // #################################################################################################################
  // ************************************************ USE EFFECTS ****************************************************
  // #################################################################################################################
  
  // set the value of yUnitsAutoComplete whenever viewPage.yaxisUnits changes
  
  
  useEffect(() => {
    
    if (viewPage?.yaxisUnits[props.id] != null) {
      setYUnitsAutoComplete(viewPage?.yaxisUnits[props.id])

      if (verbose) {
        console.log("Setting the yUnitsAutoComplete to " + viewPage?.yaxisUnits[props.id])
      }

    }
  }, [viewPage?.yaxisUnits])
  
  

  // setting available y-units
  useEffect(() => {
    
    if (queryData?.selectedProcesses != null && props.processData != null && viewPage?.yaxis[props.id] != null) {
      const availableYUnitsLocal = getAvailableYUnits(viewPage?.yaxis[props.id])
      setAvailableYUnits(availableYUnitsLocal)
      if (verbose) {
        console.log("Setting the available y-units to ", availableYUnitsLocal)
      }
    }
  }
  , [queryData?.selectedProcesses, props.processData, viewPage?.yaxis[props.id]])

  // adjust the plotWidth and plotHeight parameters based on number of plots in the view
  useEffect(() => {

    if (viewPage?.numplots >= 5) {
      setPlotWidth("32%")
      setPlotHeight(height / 2 - 95)
      setPlotFraction(0.85)
    } else if (viewPage?.numplots == 4) {
      setPlotWidth("48.5%")
      setPlotHeight(height / 2 - 95)
      setPlotFraction(0.85)
    } else if (viewPage?.numplots == 3) {
      setPlotWidth("32%")
      setPlotHeight(height - 180)
      setPlotFraction(0.9)
    } else if (viewPage?.numplots == 2) {
      setPlotWidth("48.5%")
      setPlotHeight(height - 180)
      setPlotFraction(0.9)
    } else {
      setPlotWidth("98%")
      setPlotHeight(height - 180)
      setPlotFraction(0.9)
    }
  })

  // set the legend position based on the viewPage
  useEffect(() => {
    if (viewPage?.legendPosition[props.id] != null) {
      
      const value = viewPage?.legendPosition[props.id]
      
      if (value == "TR") {
        setLegendXPosition(0.98)
        setLegendYPosition(0.98)
        setLegendXAnchor("right")
        setLegendYAnchor("top")
      } else if (value == "TL") {
        setLegendXPosition(0.02)
        setLegendYPosition(0.98)
        setLegendXAnchor("left")
        setLegendYAnchor("top")
      } else if (value == "BR") {
        setLegendXPosition(0.98)
        setLegendYPosition(0.02)
        setLegendXAnchor("right")
        setLegendYAnchor("bottom")
      } else if (value == "BL") {
        setLegendXPosition(0.02)
        setLegendYPosition(0.02)
        setLegendXAnchor("left")
        setLegendYAnchor("bottom")
      } else if (value == "OR") {
        setLegendXPosition(1.02)
        setLegendYPosition(1)
        setLegendXAnchor("left")
        setLegendYAnchor("top")
      }

    }
  }, [viewPage?.legendPosition])


  // unit conversion
  useEffect(() => {

    if (verbose) {
      console.log("> Executing the unit conversion useEffect")
    }

    // variables for executing the unit conversion query
    let key: any = ''
    let process: any = {}
    let variable_data_query_ids: any = []
    let variable_data_query_units: any = []


    // loop over the processes in the view
    for ([key, process] of Object.entries(props.processData)) {

      // if queryData.selectedProcesses includes process.id
      if (queryData?.selectedProcesses.find((x: any) => x.id == process.id)) {

        // find the variable in the process that has the same name as the needed y-variable
        let variable = process.variableSet.find((v: any) => v.name == viewPage?.yaxis[props.id])

        // if the variable is not found, skip to the next process
        if (variable == null) {
          continue
        }

        if (viewPage?.yaxisUnits[props.id] != variable?.units) {
          variable_data_query_ids.push(variable?.id)
          variable_data_query_units.push(viewPage?.yaxisUnits[props.id])
          if (verbose) {
            console.log("Unit conversion needed for: ", variable?.name , " from ", variable?.units, " to ", viewPage?.yaxisUnits[props.id])
          }
        }

        if (xAxisName != "Date" && xAxisName != "ProcessTime") {

          // find the variable in the process that has the same name as the needed x-variable
          const x_var = process.variableSet.find((v: any) => v.name == xAxisName)

          // if the variable is not found, skip to the next process
          if (x_var == null) {
            continue
          }

          // set xData and xUnits
          let xData = x_var?.data[0]

          // if the variable is not found, skip to the next process
          if (xData == null) {
            continue
          }

          // if the selected x-axis units are not the same as the units of the variable, convert the data
          if (xAxisUnits != x_var?.units) {
            variable_data_query_ids.push(x_var?.id)
            variable_data_query_units.push(xAxisUnits)
          }
        }
      }
    }

    // execute the unit conversion query
    if (variable_data_query_ids.length > 0 ) {
      if (verbose) {
        console.log(">> Executing an actual unit conversion query")
        console.log('>> variable_data_query_ids: ' + variable_data_query_ids)
        console.log('>> variable_data_query_units: ' + variable_data_query_units)
      }
      getVariableData({ variables: { input: { ids: variable_data_query_ids, units: variable_data_query_units } } })
    }
  }
    , [queryData?.selectedProcesses, props.processData, props.processFetchingComplete, xAxisName, xAxisUnits, viewPage?.yaxis[props.id], viewPage?.yaxisUnits[props.id]])

  // use effect to set the plot data, the available units and in some cases the selected units
  useEffect(() => {

    if (verbose) {
      console.log("> Executing the plot data useEffect")
    }

    // prepare for the loop over the processes

    let key: any = ''
    let process: any = {}
    let totalNumberOfDataPoints = 0
    let plotDataLocal: any = []
    let groupedPlotDataLocal:any = {};


    // loop over the processes in the view

    // copy processData and sort it by name
    let processData = props?.processData.slice()
    if (processData != null) {
      processData = processData.sort((a: any, b: any) => a.name.localeCompare(b.name))
    }

    // defaultProcessColors should contain the id of the process and the assigned color
    let defaultProcessColors: any = {}
    
    // for each process data, assign a color from the colors array in the same order as the processes are sorted
    const colors = ['#0072BD', '#D95319', '#EDB120', '#7E2F8E', '#77AC30', '#4DBEEE', '#A2142F']

    // for each process add a key to the defaultProcessColors object with the process id and the color
    // start from the beginning of the colors array if there are more processes than colors
    /*for (let i = 0; i < processData.length; i++) {
      defaultProcessColors[processData[i].id] = colors[i % colors.length]
    }
    */

    // for each process means analysis.processes
    for (let i = 0; i < props.analysis.processes.length; i++) {
      defaultProcessColors[props.analysis.processes[i].id] = colors[i % colors.length]
    }

    const groupByMetaData = props.analysis.processExplorerView?.groupByMetaData

    let symbol:any = 100
    let size = 10
    if (plotMode=="dots") {
      // i want the symbol to be a small filled circle
      symbol = "circle"
      plotMode = "markers"
      size = 4
    }

    // loop over the processes in the view
    for ([key, process] of Object.entries(processData)) {

      // if queryData.selectedProcesses includes process.id and if view.processesForDisplay includes process.id
      if (queryData?.selectedProcesses.find((x: any) => x.id == process.id) && props.analysis.processExplorerView?.processesForDisplay.find((x: any) => x.id == process.id)) {

        // find the variable in the process that has the same name as the needed y-variable
        let variable = process.variableSet.find((v: any) => v.name == viewPage?.yaxis[props.id])

        // if the variable is not found, skip to the next process
        if (variable == null) {
          continue
        }

        let yData = variable?.data[0]
        let yDataTime = variable?.time

        // if the selected y-axis units are not the same as the units of the variable, get the data from variableDataData
        if (viewPage?.yaxisUnits[props.id] != variable?.units) {

          if (variableDataData == null) {
            continue
          }

          // find the variable in the variableDataData that has the same id and units as the needed y-variable
          let varkeys: any = []
          let vars: any = []
          for ([varkeys, vars] of Object.entries(variableDataData.variableData)) {
            if (vars?.id == variable?.id && vars?.units == viewPage?.yaxisUnits[props.id]) {
              yData = vars?.data[0]
            }
          }
        }

        // get the data for the x-axis
        let xData: any = []

        if (xAxisName === "Date") {
          xData = variable?.time

        } else if (xAxisName === "ProcessTime") {
          xData = variable?.processTime

          // if the x-axis units are not hours, convert the data
          if (xAxisUnits == "days") {
            xData = xData.map((x: any) => x / 24)
          } else if (xAxisUnits == "minutes" || xAxisUnits == "min") {
            xData = xData.map((x: any) => x * 60)
          } else if (xAxisUnits == "seconds" || xAxisUnits == "sec") {
            xData = xData.map((x: any) => x * 3600)
          } else if (xAxisUnits == "year" || xAxisUnits == "years") {
            xData = xData.map((x: any) => x / 8760)
          } else if (xAxisUnits == "week" || xAxisUnits == "weeks") {
            xData = xData.map((x: any) => x / 168)
          } else if (xAxisUnits == "month" || xAxisUnits == "months") {
            xData = xData.map((x: any) => x / 730)
          } else if (xAxisUnits == "hours" || xAxisUnits == "hour" || xAxisUnits == "h") {
            // do nothing
          } else {
            // return an empty plot if the units are not recognized
            setPlotData([])

            return
          }

          // if x-axis is something other than processTime or date
        } else {

          // find the variable in the process that has the same name as the needed x-variable
          const x_var = process.variableSet.find((v: any) => v.name == xAxisName)

          // set xData and xUnits
          xData = x_var?.data[0]
          let xDataTime = x_var?.time

          // if the variable is not found, skip to the next process
          if (xData == null) {
            continue
          }

          // if the selected x-axis units are not the same as the units of the variable, get the data from variableDataData
          if (xAxisUnits != x_var?.units) {
            // set the variable to the converted variable
            if (variableDataData == null) {
              continue
            }

            let varkeys: any = []
            let vars: any = []
            for ([varkeys, vars] of Object.entries(variableDataData.variableData)) {
              if (vars?.id == x_var?.id && vars?.units == xAxisUnits) {
                xData = vars?.data[0]
              }
            }
          }
          // for cases where x is not process time or date, we need to interpolate the data
          // it is possible that xData and yData have different lengths
          // we need to bring xDataTime to the same time as yDataTime
          let xNew = interp1(xDataTime, xData, yDataTime)
          xData = xNew

        }

        
        // generate the texts array
        let texts = Array(xData.length).fill(process.name)

        // function to generate a simple hash for a string - used to assign a color to a metadata value
        const simpleHash = (str:any) => {
          let hash = 0;
          for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash; // Convert to 32bit integer
          }
          return Math.abs(hash);
        };

        // Assuming `colors` is your array of available colors
        const getColorForMetadataValue = (metaValue:any) => {
          const index = simpleHash(metaValue) % colors.length;
          return colors[index];
        };

        // create an array for customData that contains the id of each process for each data point
        let ids = Array(xData.length).fill(process.id)
        let customDataForPoints = xData.map((x: any, i: any) => {
          return {
            processId: process.id,
          }
        }
        )

        //totalNumberOfDataPoints = totalNumberOfDataPoints + yData.length

        const metaName = props.analysis.processExplorerView?.metaDataForGrouping
        // get the value of the metadata for the process
        let metaValue = process.metadataSet.find((x: any) => x.name == metaName)?.value
        if (metaValue == null) {
          metaValue = "Undefined"
        }

        ///////////////////////////////////////////////////////////////////////////
        // group by metadata
        if (groupByMetaData) {

          // for each metadata value, we need a different color
          //let metaDataColor = colors[Object.keys(groupedPlotDataLocal).length % colors.length]
          let metaDataColor = getColorForMetadataValue(metaValue)
          
          // if the groupedPlotDataLocal does not contain the metadata value, add it
          if (!groupedPlotDataLocal[metaValue]) {

            groupedPlotDataLocal[metaValue] = {
              x: [],
              y: [],
              type: "scatter",
              mode: plotMode,
              name: metaValue, // Group name is the metadata value
              // add the process name text box that opens when hovering over the line
              hoverinfo: 'text-name',
              text: [],
              
              marker: {
                color: metaDataColor,
                size: lineWidth + size,
                symbol: symbol,
              },
              line: {
                color: metaDataColor,
                width: lineWidth,
              },
              id: [],
              customdata: customDataForPoints, // Assigning custom data here
            };
          }

          // add the data to the groupedPlotDataLocal
          groupedPlotDataLocal[metaValue].x.push(...xData, null); // Add null to separate data from different processes
          groupedPlotDataLocal[metaValue].y.push(...yData, null); // Add null to separate data from different processes
          groupedPlotDataLocal[metaValue].text.push(...texts, null); // Add null to separate data from different processes
          //groupedPlotDataLocal[metaValue].id.push(...ids, null); // Add null to separate data from different processes

          // add the data to the plotData array
          //plotDataLocal.push(...Object.values(groupedPlotDataLocal))
        
        ///////////////////////////////////////////////////////////////////////////
        // group by process name
        } else {

          // get the defaut color for the process
          let color = defaultProcessColors[process.id]

          // check if the view contains a processColor entry for this process
          let processColor = props.analysis.processExplorerView?.processcolorSet.find((x: any) => x.process.id == process.id)

          // if process color is not null or an empty string, set the color to the process color
          if (processColor != null && processColor.color != "") {
            color = processColor.color
          }
        
          // add the data to the plotData array
          plotDataLocal.push(
            {
              x: xData,
              y: yData,
              type: "scatter",
              mode: plotMode,
              name: process.name,
              text: texts,
              hoverinfo: 'text-name',
              id: process.id,

              marker: {
                color: color,
                size: lineWidth + size,
                symbol: symbol,
              },
              line: {
                color: color,
                width: lineWidth,
              }
            }
          )

        }
      }
    }

    /*let sortedPlotData = plotDataLocal.sort((a: any, b: any) => {
      return a.marker.color.localeCompare(b.marker.color)
    })*/

    if (groupByMetaData) {
      plotDataLocal = Object.values(groupedPlotDataLocal)
    }

    setPlotData(plotDataLocal)
    //setPlotData(sortedPlotData.reverse())
    if (verbose) {
      console.log('plotDataLocal: ', plotDataLocal)
    }

   }, [props.analysis.processExplorerView, viewPage.yaxis[props.id] , viewPage.yaxisUnits[props.id], props.processData, queryData?.selectedProcesses, variableDataData, props.processFetchingComplete])

 
   // use Memo to create the content for variable info table
   // each selected process should have a row in the table
   // one column should be the number of data points in the variable, the other whether the cariable is calculated or measured

   const variableInfoTableContent = useMemo(() => {
    if (props.processData != null) {
      return (
        <Table
          scroll={{ y: 500 }}
          columns={[
            {
              title: 'Process',
              dataIndex: 'process',
              key: 'process',
            },
            {
              title: 'Units',
              dataIndex: 'units',
              key: 'units',
            },
            {
              title: 'Data Points',
              dataIndex: 'dataPoints',
              key: 'dataPoints',
            },
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
            }, 
            {
              title: 'Calculator',
              dataIndex: 'calculator',
              key: 'calculator',
            }
          ]}
          dataSource={props.processData
            .map((process: any) => {
              const variable = process.variableSet.find(
                (v: any) => v.name == viewPage?.yaxis[props.id]
              );
  
              if (props.analysis.processExplorerView.processesForDisplay.find((x: any) => x.id == process.id)) {
                return {
                  key: process.id,
                  process: process.name,
                  units: variable?.units || "Not Available",
                  dataPoints: variable?.data?.[0]?.filter((x: any) => x != null).length || 0, // count only non-null data points
                  type: variable
                    ? variable.calculator
                      ? "Calculated"
                      : "Measured"
                    : "Not Available",
                  calculator: variable
                    ? variable.calculator
                      ? variable.calculator.name
                      : "Not Calculated"
                    : "Not Available",
                };
              }
              return null; // return null for processes not found
            })
            .filter((row: any) => row !== null)} // filter out null values
          pagination={false}
        />
      );
    }
  }, [props.processData, viewPage?.yaxis[props.id], props.analysis.processExplorerView, queryData?.selectedProcesses]);
  

    // Prepare allXData and allYData once and store them in a single memoized object
    const { allXData, allYData } = useMemo(() => {
      const allXData = plotData.flatMap((d:any) => d.x);
      const allYData = plotData.flatMap((d:any) => d.y);
      return { allXData, allYData };
    }, [plotData]);

    // Calculate xMin
    const xMin = useMemo(() => {
      if (viewPage?.xaxisMin != null) return viewPage.xaxisMin;

      let minVal = allXData[0] || 0; // Default to 0 if allXData is empty
      for (const x of allXData) {
        if (x < minVal) minVal = x;
      }
      return Math.round(minVal * 0.9);
    }, [allXData, viewPage?.xaxisMin]);

    // Calculate xMax
    const xMax = useMemo(() => {
      if (viewPage?.xaxisMax != null) return viewPage.xaxisMax;

      let maxVal = allXData[0] || 0;
      for (const x of allXData) {
        if (x > maxVal) maxVal = x;
      }
      return Math.ceil(maxVal / 10) * 10;
    }, [allXData, viewPage?.xaxisMax]);

    // Calculate yMin
    const yMin = useMemo(() => {
      if (viewPage?.yaxisMin[props.id] != null) return viewPage.yaxisMin[props.id];

      let minVal = allYData[0] || 0;
      for (const y of allYData) {
        if (y < minVal) minVal = y;
      }
      minVal = Math.round(minVal * 0.9);

      // Adjust if within 3 of zero
      return minVal > -3 && minVal < 3 ? 0 : minVal;
    }, [allYData, viewPage?.yaxisMin, props.id]);

    // Calculate yMax
    const yMax = useMemo(() => {
      if (viewPage?.yaxisMax[props.id] != null) return viewPage.yaxisMax[props.id];

      let maxVal = allYData[0] || 0;
      for (const y of allYData) {
        if (y > maxVal) maxVal = y;
      }
      maxVal = maxVal * 1.1;
      return maxVal > 1 ? Math.round(maxVal) : maxVal;
    }, [allYData, viewPage?.yaxisMax, props.id]);


   // use effect to set the plot shapes
  useEffect(() => {
    if (verbose) {
      console.log("> Executing the plot shapes useEffect")
    }

    // get all processexplorerannotationSets of the view
    let processexplorerannotationSet = props.analysis.processExplorerView?.processexplorerannotationSet

    // filter annotations with the same xAxis as the current plot
    let filteredAnnotations = processexplorerannotationSet?.filter((x: any) => x.xAxis == xAxisName)


    let plotShapesLocal: any = []
    let scatterDataLocal: any = []
    
    // loop over the annotations
    for (let i = 0; i < filteredAnnotations?.length; i++) {
      let annotation = filteredAnnotations[i]
      // if the annotation is for the current y-axis

      plotShapesLocal.push(
        {
          type: 'line',
          x0: annotation.xValue,
          x1: annotation.xValue,
          y0: 0,
          y1: 1,
          xref: 'x',
          yref: 'paper',
          
          line: {
            color: annotation.color,
            width: 1,
            //dash: annotation.lineDash,
          },
        }
      )

      let label = annotation.label
      if (label == null) {
        label = ""
      }

      // Add scatter data for each line
      scatterDataLocal.push({
        x: Array(10).fill(annotation.xValue),
        //y: Array(10).fill(0).map((_, idx, arr) => idx / (arr.length - 1)), // Fill with 0 and then map
        y: Array(10).fill(0).map((_, idx, arr) => idx / (arr.length - 1)).map(x => x * (yMax - yMin) + yMin), // Fill with 0 and then map
        type: 'scatter',
        mode: 'markers',
        marker: { opacity: 0 },
        hoverinfo: 'text',
        text: label + ' <br>' + annotation.xAxis + ': ' + annotation.xValue + ' (' + annotation.xUnits + ')',
        showlegend: false,
        // set the color to the color of the annotation
        hoverlabel: {
          bgcolor: annotation.color, // Set background color of the hover label
          bordercolor: annotation.color, // Optional: set border color if needed
          font: {
            color: 'white' // Set text color for contrast, adjust as needed
          }
        },
        name: annotation.label,
      });
      
    }

    setPlotShapes(plotShapesLocal)
    setScatterData(scatterDataLocal)

  }

    , [props.analysis.processExplorerView, xAxisName, xAxisUnits, yMin, yMax])

    /*
    const startTime = performance.now();

    useEffect(() => {
      // Record the end time and calculate the duration after the component renders
      const endTime = performance.now();
      console.log(`YourComponent loading time: ${endTime - startTime} milliseconds`);
    }, []); // Empty dependency array ensures this runs once on mount
    */

    // ####################################################################################################################
    // END OF USE EFFECTS
    // ####################################################################################################################
    //console.log('totalNumberOfDataPoints: ', totalNumberOfDataPoints)
    
    

    // Number of grids you want to specify
    /*
    let numberOfXGrids = 5;

    // if xMax - xMin is divisble by 10, then set numberOfGrids to 10
    if ((xMax - xMin) % 10 == 0) {
      if ((xMax-xMin) / 10 < 10) {
        numberOfXGrids = (xMax - xMin) / 10;
      } else {
        numberOfXGrids = 10;
      }

    } else if ((xMax - xMin) % 4 == 0) {
      numberOfXGrids = 4;
    } else if ((xMax - xMin) % 5 == 0) {
      numberOfXGrids = 5;
    } else if ((xMax - xMin) % 6 == 0) {
      numberOfXGrids = 6;
    } else if ((xMax - xMin) % 7 == 0) {
      numberOfXGrids = 7;
    } else if ((xMax - xMin) % 8 == 0) {
      numberOfXGrids = 8;
    } else if ((xMax - xMin) % 9 == 0) {
      numberOfXGrids = 9;
    }
    */

    let numberOfYGrids = 5;

  


  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 17 },
  };

  const popoverContent = (
    <div style={{ width: 370, height: 350 }}>
      <Form
        {...formLayout}
        labelAlign="left"
      >
        
        <Form.Item label="Y-Units" >
          <Space direction="horizontal">

            <AutoComplete
              value={yUnitsAutoComplete}
              defaultValue={yUnitsAutoComplete}
              onChange={onChangeYUnits}
              data-testid="yaxis-units-selection"
              size="small"
              style={{ width: 200 }}
            >
              {/* loop over available y-units and create an option for each */}
              {availableYUnits.map((unit: any) => { return (<Option key={unit} value={unit}>{unit}</Option>) })}
            </AutoComplete>

            <Button
              type="default"
              size="small"
              onClick={onSubmitChangeYUnits}
              data-testid="yaxis-units-set-button"
            >
              Set
            </Button>
          </Space>
        </Form.Item>

        <Form.Item label="Y-min" >
          <InputNumber size="small" onChange={onChangeYAxisMin} value={viewPage?.yaxisMin[props.id]} defaultValue={viewPage?.yaxisMin[props.id]} data-testid="y-axis-min-input" />

        </Form.Item>
        <Form.Item label="Y-max" >
          <InputNumber size="small" onChange={onChangeYAxisMax} value={viewPage?.yaxisMax[props.id]} defaultValue={viewPage?.yaxisMax[props.id]} data-testid="y-axis-max-input" />
        </Form.Item>

        <Form.Item label="Line width">
          <div data-testid="line-width-slider">
            <Slider
              min={1}
              max={6}
              defaultValue={lineWidth}
              onChange={onChangeLineWidth}
              style={{ width: "100%" }}
              //data-testid="line-width-slider"
            />
          </div>
        </Form.Item>

        <Form.Item label="Font size">
          <div data-testid="font-size-slider">
          <Slider
            min={8}
            max={16}
            defaultValue={axisTitleSize}
            onChange={setAxisTitleSize}
            style={{ width: "100%" }}
            //data-testid="font-size-slider"
          />
          </div>
        </Form.Item>

    {/* select with multiple legend position options e.g. TR, TL, BR, BL */}
    <Form.Item label="Legend position">
      <Select
        value={viewPage?.legendPosition[props.id]}
        onChange={onChangeLegendPosition}
        size="small"
        data-testid="legend-position-selection"
      >
        <Option key="TR" value="TR">Top-right</Option>
        <Option key="TL" value="TL">Top-left</Option>
        <Option key="BR" value="BR">Bottom-right</Option>
        <Option key="BL" value="BL">Bottom-left</Option>
        <Option key="OR" value="OR">Outside-right</Option>
        
      </Select>
    </Form.Item>


      </Form>
    </div>
  )

  if (props.loading || (queryLoading && !queryData) || viewPage == undefined) {
    return (
      <Card
        style={{ marginLeft: 10, marginBottom: 10, height: plotHeight }}
        size="small"
        bordered={true}
        headStyle={{ border: 0, padding: 0, height: 10 }}

      >
        <Skeleton paragraph={{ rows: 6 }} active />
      </Card>
    )
  }

  let xAxisTitle = ""
  if (xAxisName != null) {
    xAxisTitle = xAxisName + " [" + xAxisUnits + "]"
  }

  if (xAxisName == "Date") {
    xAxisTitle = "Date"
  }

  let yAxisTitle = ""
  if (viewPage?.yaxis[props.id] != null) {
    yAxisTitle = viewPage?.yaxis[props.id] + " [" + viewPage?.yaxisUnits[props.id] + "]"
  }

  // set the background color of the plot
  let plotBgColor = "white"
  let axisColor = "black"

  if (queryData?.userProfile.darkMode) {
    plotBgColor = "#141414"
    axisColor = "white"
  }

  let xAxisTickFormat = '.3'
  if (xAxisName == "Date") {
    xAxisTickFormat = ''
  }

  
  // if plotData is empty and props.processFetchingComplete is true, return an empty plot and write on it: "Loading ..."
  if (plotData.length == 0 && !processesLoadingVar == false && queryData?.selectedProcesses != null && queryData?.selectedProcesses.length > 0) {
    
    let annotations:any = []

    if (props.processFetchingComplete == false ) {
      annotations = [
        {
          x: 0.5,
          y: 0.5,
          xref: 'paper',
          yref: 'paper',
          text: 'Loading in progress...',
          showarrow: false,
          font: {
            size: 16,
            color: axisColor
          },
          align: 'center',
          bgcolor: plotBgColor,
          opacity: 0.8,
          borderpad: 10,

        }
      ]
    }

    return (
      
      <div>
       
        <Card
          style={{ marginLeft: 10, marginBottom: 10, height: plotHeight }}
          size="small"
          bordered={true}
          headStyle={{ border: 0, padding: 0, height: 10 }}
        >

          <div>

            <Form.Item label="Y-axis" style={{ marginBottom: 0, marginLeft:40 }}>
              <Space>

              <Select
                  showSearch
                  defaultValue={viewPage?.yaxis[props.id]}
                  value={viewPage?.yaxis[props.id]}
                  onChange={onChangeYAxis}
                  size="small"
                  style={{ width: 150 , marginLeft:20}}
                  data-testid={"yaxis-selection-" + props.id}
                >
                  <Option key=" " value=" "> </Option>
                  {props.analysis.uniqueVars.map((uniqueVar: any) => { return (<Option key={uniqueVar} value={uniqueVar}>{uniqueVar}</Option>) })}
                </Select>


                <Select
                  defaultValue="lines"
                  onChange={onChangePlotMode}
                  value={plotMode}
                  size="small"
                  data-testid="plot-mode-selection"
                >
                  <Option key="lines" value="lines">--</Option>
                  <Option key="lines+markers" value="lines+markers">-o</Option>
                  <Option key="markers" value="markers">o</Option>
                  <Option key="dots" value="dots">.</Option>
                </Select>
              </Space>

            </Form.Item>

          {/* add the plot controls to the top right corner of the plot and have 10 points margin between this and next element which is the plot*/}
            <div style={{ position: "absolute", top: 0, right: 0, zIndex: 1 , marginRight:10}}>

              <Space direction="horizontal" size="small" style={{ marginBottom: 0 }}>

              <Popover content={popoverContent} placement="top">
                  <Button size="small" type="default" style={{ marginTop:10 }} data-testid={"plot-controls-button"}>
                    <SettingOutlined />
                  </Button>
                </Popover>

                {/*
                <Button size="small" type="link" style={{ paddingLeft: 0 }} onClick={() => setIsModalVisible(true)} data-testid={"plot-expand-button"} title="Expand plot"> 
                  <ExpandOutlined />
                </Button>
                */}

              </Space>
            </div>

          </div>
          
          <div data-testid={"plot-" + props.id}>
            <Plot
              
              data={[]}
              useResizeHandler={false}
              style={{ width: "100%" }}

              layout={
                {
                  autosize: true,
                  height: plotFractiom * plotHeight,
                  plot_bgcolor: plotBgColor,
                  paper_bgcolor: plotBgColor,

                  margin: {
                    l: 60,
                    r: 5,
                    b: 40,
                    t: 30
                  },

                  annotations: annotations,

                  xaxis: {
                    title: xAxisTitle,
                    range: [viewPage?.xaxisMin, viewPage?.xaxisMax],
                    mirror: false,
                    linewidth: 1,
                    color: axisColor,
                    ticks: 'inside',
                    tickformat: xAxisTickFormat,
                    zeroline: false,
                    tickcolor: axisColor,
                    linecolor: axisColor,
                    //gridcolor: "#cccccc",
                    //dtick:20,
                    //nticks:10,
                  },

                  yaxis: {
                    // TODO: add support for y-axis range
                    title: yAxisTitle,
                    range: [viewPage?.yaxisMin[props.id], viewPage?.yaxisMax[props.id]],
                    mirror: false,
                    linewidth: 1,
                    color: axisColor,
                    ticks: 'inside',
                    tickformat: '.3',
                    zeroline: false,
                    tickcolor: axisColor,
                    linecolor: axisColor,
                    
                    //gridcolor: "#cccccc",

                  },
                  showlegend: viewPage?.showLegend,
                  legend: {
                    borderwidth: 1,
                    bordercolor: axisColor,
                    font: {
                      color: axisColor
                    },
                  },


                }}
              config={{
                displaylogo: false,
                responsive: true,
                toImageButtonOptions: {
                  format: 'svg', // one of png, svg, jpeg, webp
                  filename: 'Process_Explorer_Image',
                  scale: 1 // Multiply title/legend/axis/canvas sizes by this factor

                },
              }}
            />
          </div>

        </Card>
      </div>
    )
  }

    // Define the grid spacing

  /*
  // Function to generate tick values based on range and number of grids

  // Function to generate tick values based on range and number of grids
  const generateTicks = (min:number, max:number, numGrids:number) => {
    const range = max - min;

    // Handle edge case where min and max are equal
    if (range === 0) {
      return [min];
    }

    const spacing = range / numGrids;
    const ticks = [];

    for (let i = 0; i <= numGrids; i++) {
      ticks.push(min + i * spacing);
    }

    // Ensure the max value is included
    if (ticks[ticks.length - 1] !== max) {
      ticks.push(max);
    }

    return ticks;
  };

  console.log('plotData: ', plotData)

  */



  // Generate tick values for both axes
  //const xTicks = generateTicks(xMin, xMax, numberOfXGrids);
  //const yTicks = generateTicks(yMin, yMax, numberOfYGrids);

  

  function plotContent(fontSize: number) {
    return (
      <div data-testid={"plot-" + props.id}>
        <Plot
          ref={plotRef}
          data-testid={"plot-" + props.id}
          data={[
            ...scatterData,
            ...plotData,
            
          ]}
          useResizeHandler={true}
          style={{ width: '100%' , height:'100%' , marginTop:5}}
          onHover={plotOnHover}
          onClick={plotOnClick}
          onSelected={(event) => {
            if (event) {
                setSelectedPoints(event.points.map(point => point.pointIndex));
            }
        }}

          layout={{
              shapes: plotShapes ,
              autosize: true,
              height: plotFractiom * plotHeight,
              plot_bgcolor: plotBgColor,
              paper_bgcolor: plotBgColor,

              margin: {
                l: 75,
                r: 20,
                b: 40,
                t: 20,
              },
              // font size
              font: {
                size: fontSize,
                color: axisColor
              },

              xaxis: {
                title: xAxisTitle,
                //range: [viewPage?.xaxisMin, viewPage?.xaxisMax],
                range: [xMin, xMax*1.003],
                mirror: false,
                linewidth: 1,
                color: axisColor,
                ticks: 'inside',
                
                tickformat: xAxisTickFormat,
                zeroline: false,
                tickcolor: axisColor,
                linecolor: axisColor,
                //tickvals: xTicks,
                
                //gridcolor: "#cccccc",
                //dtick:20,
                //nticks:10,
              },

              yaxis: {
                title: {
                  text: yAxisTitle,
                  standoff: 20, //set the distance between the y-axis title and the y-axis
                },
                

                //range: [viewPage?.yaxisMin[props.id], viewPage?.yaxisMax[props.id]],
                range: [yMin, yMax*1.004],
                mirror: false,
                linewidth: 1,
                color: axisColor,
                ticks: 'inside',
                // scientific notation starting at 1e3
                tickformat: '.3',
                zeroline: false,
                tickcolor: axisColor,
                linecolor: axisColor,
                //tickvals: yTicks,
                
                //gridcolor: "#cccccc",

              },
              showlegend: viewPage?.showLegend,
              legend: {
                borderwidth: 1,
                bordercolor: axisColor,
                font: {
                  color: axisColor
                },
                x: legendXPosition,
                y: legendYPosition,
                xanchor: legendXAnchor,
                yanchor: legendYAnchor,
                
              },


            }
            
          }
          config={{
            displaylogo: false,
            responsive: true,
            toImageButtonOptions: {
              format: 'svg', // one of png, svg, jpeg, webp
              filename: 'Process_Explorer_Image',
              scale: 1 // Multiply title/legend/axis/canvas sizes by this factor

            },
          }}
        />
        
        { variableDataLoading &&
          
          <Spin size="large" style={{ position: "absolute", top: "40%", left: "55%" }} />
          
        }

      </div>
        
        
    )
  }

  return (

    <Card
      style={{ marginLeft: 10, marginBottom: 10, height: plotHeight }}
      size="small"
      bordered={false}
      //headStyle={{ border: 0, padding: 0, height: 5 }}
    >

      <div>
        <Form.Item label="Y-axis:" style={{ marginBottom: 0, marginLeft:5 }}>
          <Space>
            <Select
              showSearch
              defaultValue={viewPage?.yaxis[props.id]}
              value={viewPage?.yaxis[props.id]}
              onChange={onChangeYAxis}
              size="small"
              style={{ width: 150 , marginLeft:0}}
              data-testid={"yaxis-selection-" + props.id}
              dropdownStyle={{ width: 300 }} // Set dropdown width here
            >
              <Option key=" " value=" "> </Option>
              {props.analysis.uniqueVars.map((uniqueVar: any) => { return (<Option key={uniqueVar} value={uniqueVar}>{uniqueVar}</Option>) })}
            </Select>


            <Select
              defaultValue="lines"
              onChange={onChangePlotMode}
              value={plotMode}
              size="small"
              data-testid={"plot-mode-selection-" + props.id}
              style={{ width: 60 }}
            >
              <Option key="lines" value="lines">--</Option>
              <Option key="lines+markers" value="lines+markers">-o</Option>
              <Option key="markers" value="markers">o</Option>
              <Option key="dots" value="dots">.</Option>
            </Select>
          </Space>

        </Form.Item>

        {/* add the plot controls to the top right corner of the plot and have 10 points margin between this and next element which is the plot*/}
        <div style={{ position: "absolute", top: 0, right: 0, zIndex: 1 , marginRight:10}}>

          <Space direction="horizontal" size="small" style={{ marginBottom: 0 }}>

          {/* Button to convert to base64 */}
          {/*}
          <Button
            size="small"
            type="default"
            title="Get AI insights"
            style={{
              marginTop: 10,
              marginRight: -10,
              border: "none", // Removes the border
              backgroundColor: "transparent", // Makes the background transparent
            }}
            data-testid={"plot-to-base64-button" + props.id}
            onClick={exportPlotAsBase64}
            icon={
              <MessageOutlined style={{ color: "grey" }} /> // Set the icon color to grey
            }
          >
          </Button>
          */}

            {/* Button with an info icon that opens a modal with information about the plot */}
          <Button 
            size="small" 
            type="default" 
            title="Variable information"
            style={{ 
              marginTop:10,
              marginRight: -10,
              border:"none",
              backgroundColor:"transparent" }

            } 
            data-testid={"plot-info-button" + props.id} 
            onClick={showInfoModal}
            >
            <InfoCircleOutlined style={{ color: "grey" }}/>
          </Button>

          <Popover content={popoverContent} placement="rightBottom" >
            <Button
              size="small"
              type="default"
              style={{
                marginTop: 10,
                border: "none", // Removes the border
                backgroundColor: "transparent", // Makes the background transparent
              }}
              data-testid={"plot-controls-button" + props.id}
              icon={
                <SettingOutlined style={{ color: "grey" }} /> // Set the icon color to grey
              }
            >
            </Button>

            </Popover>

            {/*
            <Button size="small" type="link" style={{ paddingLeft: 0 }} onClick={() => setIsModalVisible(true)} data-testid={"plot-expand-button"} title="Expand plot"> 
              <ExpandOutlined />
            </Button>
            */}

          </Space>
        </div>
      </div>

      { plotContent(axisTitleSize) }

      {/*
      <ResizableModal
        visible={isModalVisible}
        handleOk={handleModalOK}
        handleCancel={handleModalCancel}
        children={plotContent}
      />
      */}

      {/*
      <ResizableAndDraggableModal
        isVisible={isModalVisible}
        title="Your Modal Title"
        onClose={() => {
          handleModalCancel();
        }}
        // You can specify width, height, minWidth, and minHeight as needed
        width={1000} // Example width
        height={400} // Example height
        minWidth={300} // Example minimum width
        minHeight={200} // Example minimum height
      >
        {plotContent}
      </ResizableAndDraggableModal>
      */}

      <Modal 
        open = {isModalVisible}
        onCancel={handleModalCancel}
        footer = {null}
        width="80%"
        closeIcon = {<CloseOutlined />}
        destroyOnClose={true}
        style={{top:50}}
      >
        <div style={{marginTop:25, height:height-200}}>
          {plotContent(14)}
        </div>
        
      </Modal>

      <Modal
        title={"Variable information: " + viewPage?.yaxis[props.id] + " [" + viewPage?.yaxisUnits[props.id] + "]"}
        open={infoModalVisible}
        onCancel={handleInfoModalCancel}
        width="70%" 
        footer={[]}
      >
        
        {variableInfoTableContent}

      </Modal>



    </Card>
  )

}

export default ProcessExplorerPlot;
