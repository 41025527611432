import { List } from "antd";
import React from "react";
import bpi_calculators_intro_EN from '../videos/bpi_calculators_intro_EN.mp4'
import bpi_calc_math_En from '../videos/bpi_calc_math_EN.mp4'

// sections 

let sections:any = [
    {
        sectionId : "general",
        title: "Introduction",
    },
    {
        sectionId : "team",
        title: "Team",
    },
    {
        sectionId : "analysis",
        title: "Analysis",
    },
] 

// create an object that contains a list of elements, each element should have a key and a value

let documentation:any = []

// ################################################################################################################################
// ################################################################################################################################
// ################################################################################################################################

documentation.push({
    id: 0,
    "title": "Bioprocess Intelligence",
    "sectionId": "general",
    "content": (
        <div>
            <p>
                Bioprocess Intelligence is a software designed for the management and analysis of (bio-) process datasets. It enables efficient data handling, visualization of processes, and detailed analysis, with the aim of deepening the understanding of process dynamics. The software is organized into two primary sections: the Team Area and the Analysis Area.
            </p>

            <p>
                The Team Area focuses on collaborative data management, featuring:
                <ul>
                    <li><strong>Collections:</strong> Efficient organization and accessibility of process datasets.</li>
                    <li><strong>Import:</strong> Integration of data from various sources.</li>
                    <li><strong>Media:</strong> Management of media recipes, which can be incorporated into calculations, such as mass balances, to enhance the accuracy and relevance of results.</li>
                    <li><strong>Calculations:</strong> Execution of calculations on the level of collections or individual processes.</li>
                    
                    <li><strong>Lexicon:</strong> Standardization of the naming for variables, parameters, and metadata, and quality checking of process data regarding the existence and values of these entities.</li>
                </ul>
            </p>
            <p>
                The Analysis Area allows for comprehensive examination of process data and includes:
                <ul>
                    <li><strong>Process Explorer:</strong> A tool for visualizing and comparing process datasets, facilitating the identification of patterns and anomalies.</li>
                    <li><strong>KPI Definition and Analysis:</strong> Definition, tracking, and statistical analysis of Key Performance Indicators to evaluate and optimize process performance.</li>
                    <li><strong>Modeling:</strong> Advanced tools for both statistical and mechanistic modeling, employing multivariate analysis techniques to explore and predict process behaviors and outcomes.</li>
                </ul>
                These tools in the Analysis Area provide users with the ability to conduct in-depth analyses of process data, whether for team-based projects or individual research initiatives.
            </p>
        </div>
    )
})

{/*
documentation.push({
    id: 201,
    "title": "Selecting a Team",
    "sectionId": "general",
    "content": (
        <div>
           
        </div>
    )
})

documentation.push({
    id: 202,
    "title": "Selecting an Analysis",
    "sectionId": "general",
    "content": (
        <div>
           
        </div>
    )
})
*/}



// ################################################################################################################################
// ################################################################################################################################
// ################################################################################################################################

// Introduction to Teams
documentation.push({
    "id": 2,
    "sectionId": "team",
    "title": "Teams",
    "description": "Introduction to Teams.",
    "keywords": ["teams"],
    "content": (
        
        <div>

            <h2>Introduction</h2>
            <p>Teams in Bioprocess Intelligence are designed to facilitate collaborative work by allowing groups of users to share processes, data, and tools effectively. They are essential for organizing and managing collective efforts in bioprocess analysis and optimization.
            </p>

            <h2>Team Structure</h2>
            <p>
                <ul>
                    <li>Collections: Each team can contain multiple collections, which are groupings of related processes.</li>
                    <li>Processes: Within each collection, there can be several individual processes. These are the fundamental units of data and analysis within the team.</li>
                </ul>
            </p>

            <h2>Access and Permissions</h2>

            <ul>
                <li>Read-Only Access: Users with this level of access can view processes, media, calculators, etc. but cannot make any changes.</li>
                <li>Read and Write Access: Users with these permissions can add, modify, and remove processes, media, and calculators within the team.</li>
                <li>Team Owner: The owner has full control over the team, including adding or removing users and specifying their access levels.</li>
            </ul>
    
            <h2>Team Management</h2>
    
            <ul>
                <li>Adding/Removing Users: The team owner can add new members to the team or remove existing members.</li>
                <li>Permission Assignment: The owner assigns and adjusts permissions for each team member, tailoring their level of access according to the team’s needs.</li>
            </ul>


    
    

        </div>
    )
})

// Introduction to Collections
documentation.push({
    "id": 3,
    "sectionId": "team",
    "title": "Collections",
    "description": "Introduction to Collections",
    "keywords": ["collections"],
    "content": (
        
        <div>
            <h2>Overview</h2>
            <p>Collections in Bioprocess Intelligence function similarly to folders, serving as organizational units within teams. They are designed to house and manage process data in a structured manner, facilitating collaboration and data sharing among team members.</p>

            <h2>Unique Features of Collections</h2>

            <ul>
                <li>Calculation Sets: Each collection can define its unique set of calculations, tailored to the specific requirements of the processes it contains.</li>
                <li>Media Management: Collections contain their own set of media, which can be customized and managed independently within each collection.</li>
                <li>Lexicon: Collections can have their own lexicon, harmonizing the naming of variables, parameters, and metadata. Alternatively, they can adhere to the lexicon set by the team, ensuring consistency across the broader team structure.</li>
            </ul>

            <h2>Creation and Management</h2>

            <ul>
                <li>Creation: Collections can be created by the team owner, who has the authority to manage all aspects of the collection, including its name, description, calculator sets, etc.</li>
                <li>Modification: While team members can modify the collection, they do not have the authority to delete it. This ensures stability and integrity within the team’s data structure.</li>
            </ul>

            <h2>
                Permissions
            </h2>

            <p>Collections inherit the permission settings from their respective teams. This ensures a consistent and secure approach to data access and modification within the team.</p>





        </div>
    )
})

documentation.push({
    "id": 4,
    "sectionId": "team",
    "title": "Process",
    "subsection": true,
    "description": "Overview of Process",
    "keywords": ["process","parameters","metadata", "variable",  ],
    "content": (

        <div>

            <p>A process refers to a single bioprocess, which can be a single batch or a segment of a continuous process. Process can represent an upstream (e.g. fermentation) or downstream bioprocess. A process consists of a set of other data entities, such as variables (time-series data), parameters, metadata, and notes which are described below.</p>

            <h2>Variables</h2>

            <p>Variables are used to store data that changes over time. This could include anything from measured values and setpoints to control actions. Variables are defined by a name and a unit, and can be imported using different data importers. Measured variables can be particularly useful for understanding how a process is performing and for identifying potential issues.</p>

            <h2>Parameters</h2>

            <p>Parameters are used to store static data, such as process parameters, equipment parameters, and material properties. Parameters are defined by a name and a unit. Also, parameters can be used as input and outputs of calculators, and they can be imported using different data importers.</p>

            <h2>Meta-Data</h2>

            <p>Metadata is information that describes other data. In Bioprocess Intelligence, metadata can be used to store additional information about a process, such as the name of the process, the type of bioprocess, or the media used in the process. Metadata is defined by a name and a value. The value can represent different data types, such as a string or a reference to other entities, such as a media. Metadata can also be imported using different data importers.</p>

            <h2>Notes</h2>

            <p>Notes can be used to store additional information and observations about a process. Notes are defined by a timestamp, user, and value. The value is a string that can be used to store any information about the process. Notes can also be imported using the Excel importer.</p>


        </div>

    )
})

// Data Import
documentation.push({
    "id": 5,
    "sectionId": "team",
    "title": "Data Import",
    "description": "Overview of the different data importers available in the application.",
    "keywords": [],
    "content": (

        <div>

            <p>Bioprocess Intelligence provides several data importers that can be used to import data from different sources. The most relevant data importers are described below.</p>

            <h2>Lexicon</h2>

            <p>
                Only variables, parameters, and metadata that have a corresponding Lexicon entry can be imported. As described in the documentation page for Lexicon, these entries can be on an organization, team, or collection level.
            </p>

            <h2>Import from Excel</h2>

            <p>
            You can import process data by using the predefined  <a href="/import_template_v4.xlsx" download="import_template_v4.xlsx">Excel template</a>. The template contains a number of sheets, each representing a different data structure. The sheets are described below.
            <br/><br/>

                <ul>
                    <li>
                        Online: can be used for importing high-frequency online process data, such as measured values and setpoints. The sheet contains a number of columns, each representing a variable. The first and second columns are reserved for timestamp and process time respectively, and the other columns should contain the values of the variables. Time should be in the format dd.mm.yyyy-hh:mm:ss. The first row of the sheet contains the names of the variables. These names should match the name (or other names) entry of a Data Quality Standard (DQS) in the collection to be imported. The second row of the sheet contains the units of the variables. These units should be identical or convertible to the units of the corresponding DQS entry.
                    </li>

                    <br/>

                    <li>Offline: can be used for importing the results of offline analytics. The sheet is identical to "Online" and supports the importing of a data that have a different time resolution than the online data. Offline variables also need to be defined in the Data Quality of a collection to be imported.</li>

                    <br/>


                    <li>Metadata: this sheet can be used to import metadata. Both metadata of type string and media can be imported. The first column indicates the metadata name, and the second column contains the value of the metadata. The metadata name should match the name (or other names) entry of a Data Quality Standard (DQS) in the collection to be imported. To import metadata of type media, the name of the metadata should contain the phrase "media" in any form (e.g. "media", "Media", "MEDIA", etc.). The value of the metadata should be the name of the media to be imported. The media should be defined in the Media section of the collection to be imported. </li>

                    <br/>

                    <li>Parameters: this sheet can be used to import parameters. The first column indicates the parameter name, the second column contains the value of the parameter, and the third column contains the unit of the parameter. The parameter name should match the name (or other names) entry of a Data Quality Standard (DQS) in the collection to be imported.</li>
                </ul>
            </p>

            <h2>Downsampling</h2>
            <p>By default, downsampling is enabled for all import methods. Downsampling reduces the number of data points in time-series variables to improve software performance and user experience. Often variables are logged at data frequencies that are unnecessarily high and can negatively impact performance. For example, a temperature sensor may be logged every 10 seconds, but the temperature may not change significantly during this time. In this case, the data can be downsampled by only logging the data points where the temperature changes by a certain percentage. The downsampling algorithm uses a combination of the percent change of the variable and minimum data frequency (at least one data point per hour) to determine which data points to import.
            </p>


        </div>

    )
})

{/*
documentation.push({
    "id": 6,
    "sectionId": "team",
    "title": "Media",
    "description": "",
    "keywords": ["media"],
    "content": (
        <div></div>
    )
})
*/}

// calculators
documentation.push({
    "id": 7,
    "sectionId": "team",
    "title": "Calculations",
    "description": "Overview of the different calculators available in the application.",
    "keywords": ["calculators","calculations"],
    "content": (
        <div>
            <h2>Overview</h2>
            <p>
                In Bioprocess Intelligence, "calculators" are responsible for computing relevant physiological and engineering variables from your process data. These variables, crucial for understanding and optimizing processes, might not be directly measured. Calculators can operate on individual processes or encompass an entire collection of processes.
            </p>

            <h2>Defining Calculators</h2>
            <h3>Scope of Calculators</h3>
            <ul>
                <li><strong>Collection Calculators:</strong> Applied at the parent collection level and affect all processes within the collection.</li>
                <li><strong>Process Calculators:</strong> Local to a specific process and do not influence other processes in the collection.</li>
            </ul>

            <h3>Inputs and Outputs</h3>
            <p>Calculators can accept both variables (time-series data), parameters (constant values), and metadata as inputs and produce variable and parameter outputs.</p>

            <h3>Multiple Inputs and Outputs</h3>
            <p>A single calculator can handle multiple inputs and outputs, enhancing data processing flexibility.</p>

            <h3>Chaining Calculators</h3>
            <p>Outputs from one calculator can be used as inputs for another, allowing for a sequence of interconnected calculations.</p>

            <h3>Priority in Output</h3>
            <p>If two calculators produce the same output, the first defined calculator takes precedence, or if one is a process calculator and the other a collection calculator, the process calculator always has priority.</p>

            <h2>Calculation Sets on Collection Level</h2>
            <h3>Grouping Calculators</h3>
            <p>Calculators at the collection level can be grouped into calculation sets, with each collection capable of holding multiple such sets.</p>

            <h3>Enabling/Disabling Calculation Sets</h3>
            <p>Calculation sets can be enabled or disabled, allowing for easy management and testing of different calculation scenarios within a collection.</p>

            <h2>Conclusion</h2>
            <p>
                Calculators in Bioprocess Intelligence provide a powerful means to expand your dataset with derived variables and parameters, essential for deeper process understanding. They offer the flexibility and precision needed for sophisticated data analysis, whether working on a single process or managing a collection.
            </p>
        </div>
    )
})

documentation.push({
    "id": 8,
    "sectionId": "team",
    "subsection": true,
    "title": "Supported Units",
    "description": "Overview of supported units.",
    "keywords": ["units", "supported units"],
    "content": (
        <div>
            <p>
                Bioprocess Intelligence supports units according to the <a href="https://en.wikipedia.org/wiki/International_System_of_Units">International System of Units (SI)</a>. In addition to the base units, that is second (symbol s, the unit of time), meter (m, length), kilogram (kg, mass), ampere (A, electric current), kelvin (K, thermodynamic temperature), mole (mol, amount of substance), and candela (cd, luminous intensity), the software can accommodate coherent units for an unlimited number of additional quantities. These are called coherent derived units, which can always be represented as products of powers of the base units. The following list provides examples of some supported units. In addition some bioprocess-specific units, such as enzyme activity units (U) are also supported.
            </p>

            <h3>Absorbance</h3>
            <p>
                <ul>
                    <li>Absorbance Units (AU): AU is equivalent to dimensionless</li>
                </ul>
            </p>

            <h3>Cells</h3>
            <p>
                <ul>
                    <li>Number of Cells (cells)</li>
                </ul>
            </p>

            <h3>Concentration</h3>
            <p>
                <ul>
                    <li>Molar (M)</li>
                    <li>Millimolar (mM)</li>
                    <li>Micromolar (μM)</li>
                    <li>Nanomolar (nM)</li>
                </ul>
            </p>

            <h3>Conductivity</h3>
            <p>
                <ul>
                    <li>Siemens per Meter (S/m)</li>
                </ul>
            </p>

            <h3>Currency</h3>
            <p>
                <ul>
                    <li>US Dollar (USD)</li>
                    <li>Euro (EUR)</li>
                </ul>
            </p>

            <h3>Dimensionless and Percent</h3>
            <p>
                <ul>
                    <li>Dimensionless (dimensionless)</li>
                    <li>Percent (percent)</li>
                </ul>
            </p>

            <h3>Energy</h3>
            <p>
                <ul>
                    <li>Joule (J)</li>
                    <li>Calorie (cal)</li>
                    <li>Kilowatt-hour (kWh)</li>
                    <li>Megajoule (MJ)</li>
                </ul>
            </p>

            <h3>Enzyme Activity</h3>
            <p>
                <ul>
                    <li>Enzyme Unit (U)</li>
                    <li>Katal (kat)</li>
                </ul>
            </p>

            <h3>Length</h3>
            <p>
                <ul>
                    <li>Meter (m)</li>
                    <li>Centimeter (cm)</li>
                    <li>Millimeter (mm)</li>
                    <li>Micrometer (μm)</li>
                    <li>Nanometer (nm)</li>
                </ul>
            </p>

            <h3>Mass</h3>
            <p>
                <ul>
                    <li>Tonne (t)</li>
                    <li>Kilogram (kg)</li>
                    <li>Gram (g)</li>
                    <li>Milligram (mg)</li>
                    <li>Microgram (μg)</li>
                    <li>Nanogram (ng)</li>
                </ul>
            </p>

            <h3>Pressure</h3>
            <p>
                <ul>
                    <li>Pascal (Pa)</li>
                    <li>Bar (bar)</li>
                    <li>Millibar (mbar)</li>
                    <li>Atmosphere (atm)</li>
                    <li>Torr (torr)</li>
                    <li>Centimeters of Mercury (cmHg)</li>
                </ul>
            </p>

            <h3>Temperature</h3>
            <p>
                <ul>
                    <li>Kelvin (K)</li>
                    <li>Celsius (°C)</li>
                    <li>Fahrenheit (°F)</li>
                </ul>
            </p>

            <h3>Time</h3>
            <p>
                <ul>
                    <li>Year (yr)</li>
                    <li>Day (d)</li>
                    <li>Hour (hr)</li>
                    <li>Minute (min)</li>
                    <li>Second (s)</li>
                    <li>Millisecond (ms)</li>
                    <li>Microsecond (μs)</li>
                </ul>
            </p>

            <h3>Viscosity</h3>
            <p>
                <ul>
                    <li>Poise (P)</li>
                    <li>Centipoise (cP)</li>
                </ul>
            </p>

            <h3>Volume</h3>
            <p>
                <ul>
                    <li>Cubic Meter (m³)</li>
                    <li>Cubic Centimeter (cm**3)</li>
                    <li>Cubic Millimeter (mm**3)</li>
                    <li>Liter (L)</li>
                    <li>Milliliter (mL)</li>
                    <li>Microliter (μL)</li>
                </ul>
            </p>


          
        </div>
    )
})

// generic calculator
documentation.push({
    "id": 9,
    "sectionId": "team",
    "subsection": true,
    "title": "Generic calculator (calc_math)",
    "description": "Overview of the generic calculator.",
    "keywords": ["generic calculator", "calculator", "calc_math"],
    "content": (
        <div>
            <p>
                The generic calculator is a calculator that can be used to perform mathematical operations on variables and parameters.

            </p>

            {/*
            <p>
                <video controls width="600px" controlsList="nodownload" >
                    <source src={bpi_calc_math_En} type="video/mp4"/>
                    Sorry, your browser doesn't support videos.
                </video>
            </p>
            */}

            <p>The generic calculator can be used to calculate any variable or parameter based on any other variable or parameter by defining a mathematical expression.</p>

            <h3>Inputs:</h3> 
            <p>The inputs of the generic calculator are defined by the user. The user can define any number of inputs, and each input can be any variable or parameter. The inputs of the generic calculator are defined by definition of calculator interfaces. </p>

            <h3>Outputs:</h3> 
            <p>The results of the calculation can be either a variable or a parameter. The output generated will depend on whether the result of the expression is a time-series or a single value. For example, the output generated by the maximum (max) and minimum (min) functions will be a parameter, while the output generated by the square root (sqrt) of a variable will be another variable.</p>
            <p>Examples:</p>
            <ul>
                <li>parameter = max(variable)</li>
                <li>parameter = sqrt(parameter)</li>
                <li>variable = sqrt(variable)</li>
            </ul>

            <h3>Expression:</h3> 

            <p>Expressions can be constructed using a variety of mathematical operators and functions. Below is an overview of the supported features:</p>
            

            <h4>Mathematical Operators</h4>
            <ul>
                <li><strong>Addition (+)</strong>: Adds two numbers. Example: <code>result = 3 + 2</code></li>
                <li><strong>Subtraction (-)</strong>: Subtracts the second number from the first. Example: <code>result = 5 - 2</code></li>
                <li><strong>Multiplication (*)</strong>: Multiplies two numbers. Example: <code>result = 4 * 3</code></li>
                <li><strong>Division (/)</strong>: Divides the first number by the second. Example: <code>result = 10 / 2</code></li>
                <li><strong>Power (**)</strong>: Raises the first number to the power of the second. Example: <code>result = 2 ** 3</code></li>
                <li><strong>Parentheses ()</strong>: Used to control the order of operations. Example: <code>result = (2 + 3) * 4</code></li>
            </ul>
            <h4>Comparison Operators</h4>
            <ul>
                <li><strong>Equality (==)</strong>: Checks if two values are equal. Returns <code>True</code> if equal, <code>False</code> otherwise. Example: <code>is_equal = 3 == 3</code></li>
                <li><strong>Inequality (!=)</strong>: Checks if two values are not equal. Returns <code>True</code> if not equal, <code>False</code> otherwise. Example: <code>is_not_equal = 3 != 4</code></li>
                <li><strong>Less than or Equal to (&lt;=)</strong>: Checks if the first value is less than or equal to the second. Returns <code>True</code> or <code>False</code>. Example: <code>is_less_or_equal = 3 &lt;= 4</code></li>
                <li><strong>Greater than or Equal to (&gt;=)</strong>: Checks if the first value is greater than or equal to the second. Returns <code>True</code> or <code>False</code>. Example: <code>is_greater_or_equal = 4 &gt;= 3</code></li>
            </ul>

            <p>You can use the boolean values <code>True</code> and <code>False</code> interchangeably with the integers <code>1</code> and <code>0</code>. This dual behavior of boolean values provides flexibility in incorporating logical constructs directly into mathematical expressions.</p>

                        
            <h4><b>Default interpolation behavior</b></h4>

            <p>When performing mathematical operations on variables that have different time vectors, the generic calculator will automatically interpolate the values of the variables to the same time vector, that is the time vector of the variable with the lower data frequency. The interpolation is performed using the "linear" method.</p>

            <h4><b>Manual interpolation:</b></h4>

            <p>A variable can be interpolated into the time vector of another variable (e.g., with higher data frequency) using the interp1d command. For example, if the expression is "output = interp1d( var1 , var2 ,"linear")" the output will be the interpolation of the variable "var1" to the time vector of the variable "var2". The interpolation is performed using the "linear" method. Other interpolation methods can be used by replacing "linear" with "nearest", "nearest-up", "zero", "slinear", "quadratic", "cubic", "previous", or "next". "zero", "slinear", "quadratic" and "cubic" refer to a spline interpolation of zeroth, first, second or third order; "previous" and "next" simply return the previous or next value of the point; "nearest-up" and "nearest" differ when interpolating half-integers (e.g. 0.5, 1.5) in that "nearest-up" rounds up and "nearest" rounds down.
            </p>

            <p>
                If var2 is outside the range of var1, the "extrapolate" option has to be used to avoid an out of range error. The expression "output = interp1d( var1 , var2 ,"linear", "extrapolate")" will perform the interpolation and extrapolate the values of var1 outside the range of var2.
            </p>


            <h4><b>Accessing the time vector of a variable</b></h4>
            <p>To access the time vector of a variable, the function "time" can be used. This function returns the process time in units of time (e.g., hours).</p>

            <h4><b>Defining quantities with units</b></h4>
            <p>It is possible to define quantities with units in the expression using the "Quantity" command. For instance, if the expression is "output = Quantity(1 , "kg") , the result will be in units of mass. </p>

            <h4><b>Cumulative sum of variable elements</b></h4>

            <p>The "cumsum" command can be used to calculate the cumulative sum of elements of a variable. For example, the expression "output = cumsum( input )" will result in the cumulative sum of the elements of the input variable.</p>

            <h4><b>Outlier removal with the hampel filter</b></h4>

            <p>
                The Hampel Filter is a robust method for detecting and handling outliers in time series data. It relies on the Median Absolute Deviation (MAD) and employs a rolling window for the identification of outliers.
                The expression "output = hampel( input )" will remove outliers from the input variable using default window size of 5 and a threshold of 3. 
                The size of the moving window and the threshold for outlier detection can be specified as additional arguments.
                For example, the expression "output = hampel( input , 7, 2 )" will remove outliers from the input variable using a window size of 7 and a threshold of 2.
            </p>
           
            <h4><b>Accessing media composition</b></h4>

            <p>It is possible to use the following commands to access media composition in the calculator:</p>
            <ul>
                <li>output = mass_fraction_compound( media , "compound" )</li>
                <br/>
                <ul>
                    <li>output: the name of the output parameter (interface). The output will be in units of mass fraction (e.g. [g / kg]).</li>
                    <li>media: the name of the input interface of type metadata that refers to the media</li>
                    <li>"compound": the name of the compound e.g., "Magnesium sulfate" or "Sodium chloride". Note that this should be in double quotes.</li>
                </ul>
                <br/>

                <li>output = mass_fraction_molecule( media , "molecule" )</li>
                <br/>
                <ul>
                    <li>output: the name of the output parameter (interface). The output will be in units of mass fraction (e.g. [g / kg]).</li>
                    <li>media: the name of the input interface of type metadata that refers to the media</li>
                    <li>"molecule": the name of the molecule (e.g., "Glucose", "Sulfate", "Water"). Note that this should be in double quotes.</li>
                </ul>
                <br/>
                
                <li>output = mass_fraction_element( media , "element" )</li>
                <br/>
                <ul>
                    <li>output: the name of the output parameter (interface). The output will be in units of mass fraction (e.g. [g / kg]).</li>
                    <li>media: the name of the input interface of type metadata that refers to the media</li>
                    <li>"element": the chemical formula of the element (e.g., "H", "C", "O") Note that this should be in double quotes.</li>
                </ul>
                <br/>

                <li>output = molality_compound( media , "compound" )</li>
                <br/>
                <ul>
                    <li>output: the name of the output parameter (interface). The output will be in units of molality (e.g. [mol / kg]).</li>
                    <li>media: the name of the input interface of type metadata that refers to the media</li>
                    <li>"compound": the name of the compound e.g., "Magnesium sulfate" or "Sodium chloride". Note that this should be in double quotes.</li>
                </ul>
                <br/>

                <li>output = molality_molecule( media , "molecule" )</li>
                <br/>
                <ul>
                    <li>output: the name of the output parameter (interface). The output will be in units of molality (e.g. [mol / kg]).</li>
                    <li>media: the name of the input interface of type metadata that refers to the media</li>
                    <li>"molecule": the name of the molecule (e.g., "Glucose", "Sulfate", "Water"). Note that this should be in double quotes.</li>
                </ul>
                <br/>

                <li>output = molality_element( media , "molecule" )</li>
                <br/>
                <ul>
                    <li>output: the name of the output parameter (interface). The output will be in units of molality (e.g. [mol / kg]).</li>
                    <li>media: the name of the input interface of type metadata that refers to the media</li>
                    <li>"element": the chemical formula of the element (e.g., "H", "C", "O") Note that this should be in double quotes.</li>
                </ul>
                <br/>

                
            </ul>

            <p><b>Examples:</b></p>
            <p>output = max(vcd) </p>
            <p>Returns the maximum value of vcd as a parameter. Note that an output interface with type parameter needs to be defined to generate the output in the parameter list.</p>


        </div>
    )
})


documentation.push({
    "id": 10,
    "sectionId": "team",
    "subsection": true,
    "title": "Physiological uptake / production calculator",
    "description": "",
    "keywords": ["physiological uptake", "physiological production", "calc_uptake_production"],
    "content": (
        <div>
             <p>The physiological uptake / production calculator can be used to calculate the volumetric uptake rate of a substance using a mass-balance around the bioreactor system boundary. </p>

            <h3>Inputs:</h3>

            <ul>
                <li>
                    Subject: the name of the compound, molecule, or undefined ingredient that is the subject of the calculation, e.g., Glucose, Product, Biomass, ...
                </li>
                <li>
                    Content measurement broth: the name of the variable that refers to the mass content of the subject in broth in units of mass fraction, e.g., g/kg.
                </li>
                <li>
                    Mass reactor: the name of the variable that refers to the mass of the reactor, e.g. in units of kg, g, or t.
                </li>

                <li>
                    Initial mass source: specifies whether the initial mass of the reactor should be taken from the variable "Mass reactor" or the parameter "Initial mass."
                </li>

                <li>
                    Initial mass: the name of the parameter that refers to the initial mass of the broth in reactor. The units of the parameter should be e.g., kg, g, ...
                </li>
                <li>
                    Harvest: the name of the variable that contains the harvest amount in kg or g. Note that this variable should indicate the absolute amount of harvest at each time point and not the cumulative amount of harvest.
                </li>
                <li>
                    Feed1/Feed2 cum: the name of the variable that refers to the cumulative feed 1 or 2 flow in units of mass.
                </li>
                <li>
                    Feed1/Feed2 media: the name of the metadata that refers to the media of feed 1 or feed 2. If "Feed1 media" / "Feed2 media" is not defined, then "Feed1 media content" / "Feed2 media content" should be defined.
                </li>
                <li>
                    Feed 1 / 2 media content: the name of the parameter that refers to the content of the subject in feed 1 / 2 media, e.g. in units of g/kg.
                </li>

                <li>
                    Batch media initial source: specifies whether the amount of the subject in batch media should be taken from the measurement, media, or parameter.
                </li>

                <li>
                    Batch media: the name of the metadata that refers to the media of the batch.
                </li>

            </ul>

            <h3>Outputs:</h3>

            <ul>
                <li>
                    The volumetric uptake / production rate, e.g., r_Glucose
                </li>
                <li>
                    The cumulative volumetric uptake / production rate, e.g., r_Glucose_cum
                </li>
                <li>
                    The volumetric rates divided by mass, e.g. r_Glucose_mass and r_Glucose_cum_mass.
                </li>
            </ul>
        </div>
    )
})

documentation.push({
    id: 11,
    "title": "Reference-time calculator",
    "sectionId": "team",
    "subsection": true,
    "content": (
        <div>
            <p>
                The reference-time calculator is a calculator that can be used to calculate alternative time variables, such as time since the start of a specific phase of the process. 
            </p>

            <h2>Tutorial video</h2>

            <div style={{width:"70%", margin:10}}>
                <div 
                    style={{position: "relative", overflow: "hidden", aspectRatio: "16 / 9" }} 
                >
                        <iframe src="https://share.synthesia.io/embeds/videos/21b8333a-4139-418e-8851-ee2f28e43591"
                            loading="lazy" 
                            title="How to use the reference-time calculator" 
                            //allowfullscreen 
                            allow="encrypted-media; fullscreen;" 
                            //style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"
                            style = {{position: "absolute", width: "100%", height:"100%", top: 0, left: 0, border: "none", padding: 0, margin: 0, overflow:"hidden"}}
                        >
                    </iframe>        
                </div>
            </div>
        </div>
    )
})

documentation.push({
    "id": 12,
    "sectionId": "team",
    "title": "Lexicon",
    "description": "",
    "keywords": ["lexicon"],
    "content": (
        <div>

            <h2>Overview</h2>
            <p>The Lexicon in Bioprocess Intelligence is a crucial feature designed to maintain consistency and integrity in the naming of variables, parameters, and metadata across collections and teams. </p>

            <h2>Key Functionalities</h2>

            <h3>1. Consistency in Naming</h3>

            <ul>
                <li>Uniformity: The Lexicon ensures that names of variables, parameters, and metadata are consistent across all levels - organization, team, and collection.</li>
                <li>Standardization: This standardization aids in preventing confusion and errors that can arise from varying terminologies used for similar data points.</li>
            </ul>

            <h3>2. Data Quality and Completeness</h3>
            <ul>
                <li>Data Integrity: By standardizing names, the Lexicon aids in maintaining the integrity and quality of process data.</li>
                <li>Completeness Check: The Lexicon is used to verify the completeness of data, ensuring that all necessary information is present and correctly labeled.</li>
            </ul>

            <h2>Levels of Definition </h2>
            <ul>
                <li>Organization Level: The organization admin can define Lexicon entries applicable across the entire organization.</li>
                <li>Team Level: The team owner can set Lexicon entries specific to their team, which can override or complement the organization-wide Lexicon.</li>
                <li>Collection Level: Lexicon entries at this level can be defined by users with write access to the team and are specific to individual collections within the team. These entries will complement or override the entries at the team and organization levels.</li>
            </ul>

            <h2>Importance in Data Import </h2>

            <ul>
                <li>Matching Requirement: When importing data into Bioprocess Intelligence, it is essential that the variable, parameter, or metadata names match an existing Lexicon entry.</li>
                <li>Non-import of unmatched data: If there is no corresponding Lexicon entry for the imported data elements, they will not be imported into the system. This feature ensures consistency and prevents the introduction of erroneous or non-standard data.</li>
            </ul>


        </div>
    )
})


// ################################################################################################################################
// ################################################################################################################################
// ################################################################################################################################

// Introduction to Analysis
documentation.push({
    "id": 100,
    "sectionId": "analysis",
    "title": "Introduction to Analyses",
    "description": "Introduction to Analyses",
    "keywords": ["process explorer","process-explorer"],
    "content": (

        <div>
            <h2>Overview</h2>
            <p>The Analysis feature in Bioprocess Intelligence is a comprehensive tool designed for in-depth examination and interpretation of process data. It facilitates a deeper understanding of bioprocesses by enabling users to apply advanced analytical techniques to their data sets.</p>

            <h2>Core Functionalities</h2>

            <h3>Data Exploration</h3>

            <ul>
                <li>Process Explorer: A powerful visualization tool that allows users to compare and analyze different process datasets, revealing patterns, trends, and anomalies.</li>
            </ul>

            <h3>Key Performance Indicators (KPIs)</h3>

            <ul>
                <li>Definition and Analysis: Users can define specific KPIs and perform statistical analyses to measure and track process efficiency and effectiveness.</li>
                <li>Customization: Tailor KPIs to meet the unique requirements of different processes or organizational goals.</li>
            </ul>
                
            <h3>Modeling and Prediction</h3>
            <ul>
                <li>Statistical Modeling: Utilize various statistical models to interpret complex data sets and uncover underlying relationships.</li>
                <li>Multivariate Analysis: Perform multivariate analysis to handle high-dimensional data and extract meaningful insights.</li>
            </ul>
        </div>

    )
})

// Process Explorer
documentation.push({
    "id": 101,
    "sectionId": "analysis",
    "title": "Process Explorer",
    "description": "Overview of the Process Explorer.",
    "keywords": ["process explorer","process-explorer"],
    "content": (
        <div>
            <h2>Overview</h2>
            <p>
                The Process Explorer in Bioprocess Intelligence is a comprehensive visualization tool for real-time monitoring and analysis of bioprocess online and offline data. It allows for interactive exploration of process variables across multiple processes.
            </p>

            <h2>Interface and Navigation</h2>
            <h3>Analysis Panel</h3>
            <p>
                Users can add or remove processes for analysis through the "Add or remove processes" function, enabling the comparison of multiple data sets. Processes that are added or removed are automatically updated for the entire analysis.
            </p>

            <h3>Graphical Display</h3>
            <p>
                The central panel displays graphs for various process variables against process time or any other online or offline variable, providing insight into process behavior. The X-axis can be adjusted to e.g. examine specific time frames in detail.
            </p>

            <h3>Process Details</h3>
            <p>
                When a process is selected, its metadata, parameters, and notes are displayed, offering detailed contextual information. Users can also add line annotations to the graphs for highlighting key events or data points.
            </p>

            <h2>Features and Functionality</h2>
            <h3>Comparative Analysis</h3>
            <p>
                Facilitates side-by-side comparison of different processes to identify consistent patterns or discrepancies.
            </p>

            <h3>Data Interaction</h3>
            <p>
                Interactive graphs that support zooming and panning, with customizable variable selection for tailored data analysis.
            </p>

            <h3>Process Information Management</h3>
            <p>
                Directly manage metadata and track process parameters from within the Process Explorer.
            </p>

        </div>
    )
})

documentation.push({
    "id": 102,
    "sectionId": "analysis",
    "title": "KPI Analysis",
    "description": "Overview of the KPI Analysis.",
    "keywords": ["kpi analysis","kpi-analysis"],
    "content": (

    <div>
            <h2>KPI Analysis</h2>
            <p>KPI stands for Key Performance Indicator and can be defined from various data sources, such as variables, metadata, parameters, etc.</p>
            <p>The KPI Analysis tool lets you define and statistically analyze KPIs.</p>
            <p>The defined KPIs can be stored into KPI sets. More than one KPI set can be defined per collection.</p>
            <p>To define KPIs and KPI set, use the "Select / Manage" link under "Select a KPI set" on the menu.</p>
            <p>To get started, select processes from the menu.</p>

            <h2>Definition of KPIs:</h2>
            <p>KPIs can be specified using different functions:</p>
            <ul>
            <li>Min: returns the minimum value of a variable.</li>
            <li>Max: returns the maximum value of a variable</li>
            <li>Interp: returns the value of a variable (y) at a certain value of another variable (x).</li>
            <li>Start: returns the first value of a variable</li>
            <li>End: returns the last value of a variable</li>
            <li>Metadata: returns the value of a metadata (string)</li>
            <li>Parameter: returns the value of a parameter (numeric)</li>
            </ul>
            <p></p>

            <h2>Box plot</h2>

            <p>Boxplot is a method for graphically demonstrating the locality, spread and skewness groups of numerical data through their quartiles [1].</p>

            <p>A boxplot is a standardized way of displaying the dataset based on the five-number summary: the minimum, the maximum, the sample median, and the first and third quartiles.</p>
            <ul>
            <li>Minimum (Q0 or 0th percentile): the lowest data point in the data set excluding any outliers</li>
            <li>Maximum (Q4 or 100th percentile): the highest data point in the data set excluding any outliers</li>
            <li>Median (Q2 or 50th percentile): the middle value in the data set</li>
            <li>First quartile (Q1 or 25th percentile): also known as the lower quartile qn(0.25), it is the median of the lower half of the dataset.</li>
            <li>Third quartile (Q3 or 75th percentile): also known as the upper quartile qn(0.75), it is the median of the upper half of the dataset.</li>
            <li>Interquartile range (IQR) : the distance between the upper and lower quartiles: IQR = Q3 - Q1 </li>
            </ul>

            <h3>Notched box plot</h3>
            <p> 
                Notched box plots apply a "notch" or narrowing of the box around the median. 
                Notches are useful in offering a rough guide of the significance of the difference of medians. 
                If the notches of two boxes do not overlap, this will provide evidence of a statistically significant difference between the medians.
            </p>
            <p>The distance between the boundaries of the notches around the median is calculated by: 1.58 * IQR / sqrt(n) [2]. </p>
            
            <h4>References:</h4> 
            <p>[1] Box plot <a href="https://en.wikipedia.org/wiki/Box_plot">Wikipedia</a></p>
            <p>[2] Chambers, J. M., Cleveland, W. S., Kleiner, B. and Tukey, P. A. (1983). Graphical Methods for Data Analysis. Wadsworth & Brooks/Cole. </p>
            
        </div>

    )
})

documentation.push({
    "id": 103,
    "sectionId": "analysis",
    "title": "Modeling: Multiple Linear Regression (MLR)",
    "description": "",
    "keywords": ["mlr", "multiple linear regression"],
    "content": (
        <div>

<h2>Overview</h2>
            <p>
                Multiple Linear Regression (MLR) models are employed to discern the relationships between two or more independent variables (predictors) and a dependent variable (response). By constructing a linear equation that best fits the data, MLR provides a predictive model that can be used for both analysis and forecasting.
            </p>

            <h2>Application in Bioprocess Data Analysis</h2>
            <p>
                Within bioprocess data analysis, MLR models are instrumental in unraveling the intricate interactions between various factors that impact the results of a bioprocess. They can identify which factors are significant predictors of process outcomes and quantify their relative influence.
            </p>

            <h2>Building and Interpreting MLR Models</h2>
            <p>
                MLR models are built by fitting a linear equation to the observed data. The model coefficients indicate the strength and direction of the relationship between each independent variable and the dependent variable. Interpretation of these coefficients allows for an understanding of how changes in process parameters can affect the final product.
            </p>
            
            <h2>MLR for Optimization and Control</h2>
            <p>
                By providing a clear quantitative framework, MLR models can be used to optimize process conditions and control strategies. Adjustments to the input variables can be modeled to predict their effects on the output, guiding process improvements and control measures.
            </p>
            
            <h2>Assumptions and Considerations</h2>
            <p>
                It is critical to acknowledge that MLR models rest upon certain assumptions including linearity, independence, homoscedasticity, and normal distribution of residuals. Violations of these assumptions can lead to model inaccuracies, hence diagnostic checking is a vital part of MLR modeling.
            </p>

            <h2>Statistical Summary of MLR Models</h2>
            
            <p>When using the MLR tool within Bioprocess Intelligence, the regression results offer a detailed statistical summary of the model. These results provide valuable insights into the model's fit, the significance of predictors, and other relevant metrics. Let's dive into what each of these metrics mean:</p>

            <h3>Dep. Variable</h3>
            <p>Denotes the dependent variable, or the variable we're trying to predict or explain.</p>

            <h3>R-squared</h3>
            <p>Represents the proportion of the variance in the dependent variable predictable from the independent variables.</p>

            <h3>Adj. R-squared</h3>
            <p>An adjusted version of R-squared accounting for the number of predictors in the model.</p>

            <h3>Method: Least Squares</h3>
            <p>Indicates the OLS method used to fit the regression model.</p>

            <h3>F-statistic</h3>
            <p>Tests the hypothesis that all regression coefficients are equal to zero, suggesting the significance of the overall model.</p>

            <h3>Prob (F-statistic)</h3>
            <p>Associated p-value for the F-statistic. A small value indicates that the model fits the data better than an intercept-only model.</p>

            <h3>Log-Likelihood</h3>
            <p>Measures the model's ability to explain the observed data with higher values being better.</p>

            <h3>No. Observations</h3>
            <p>The number of data points or observations used in the regression.</p>

            <h3>AIC & BIC</h3>
            <p>Criteria for comparing models. Lower values typically suggest a better fit.</p>

            <h3>Coef</h3>
            <p>Coefficients for each predictor, representing the change in the response for a unit change in the predictor.</p>

            <h3>Std Err</h3>
            <p>Standard error of the coefficient, with smaller values indicating more precise estimates.</p>

            <h3>Omnibus Test</h3>
            <p>Tests if the residuals are normally distributed. A non-significant result indicates normal distribution.</p>

            <h3>Durbin-Watson</h3>
            <p>Tests for the presence of autocorrelation in the residuals.</p>

            <h3>Jarque-Bera (JB) Test</h3>
            <p>Another measure for testing the normality of residuals.</p>

            <h3>Skew & Kurtosis</h3>
            <p>Measure the asymmetry and 'tailedness' of the data distribution respectively.</p>

            <h3>Cond. No.</h3>
            <p>A measure that can indicate multicollinearity if values are high.</p>

        </div>
    )
});

documentation.push({
    "id": 104,
    "sectionId": "analysis",
    "title": "Modeling: Principal Component Analysis (PCA)",
    "description": "",
    "keywords": ["pca", "principal component analysis"],
    "content": (
        <div>
            <h2>Overview</h2>
            <p>
                Principal Component Analysis (PCA) is a statistical procedure that transforms a set of potentially correlated variables into a set of linearly uncorrelated variables known as principal components. By performing an orthogonal transformation, PCA simplifies complex multi-dimensional datasets to lower dimensions, facilitating easier visualization and interpretation.
            </p>
            
            <h2>Significance in Exploratory Data Analysis</h2>
            <p>
                In exploratory data analysis, PCA is invaluable for uncovering the underlying structure of the data. It helps in identifying patterns, trends, and key variables that contribute significantly to variability within the dataset. By reducing dimensionality, PCA allows analysts to focus on the most important data aspects without the noise of less relevant information.
            </p>
            
            <h2>PCA in Bioprocess Data Analysis</h2>
            <p>
                When applied to bioprocess data analysis, PCA evaluates the Key Performance Indicators (KPIs) that are critical for understanding process performance. It enables the identification of the most influential KPIs in the variability of the processes and helps in the optimization of process conditions.
            </p>
            
            <h2>Understanding Loadings and Score Plots</h2>
            <p>
                <strong>Loadings:</strong> Loadings in PCA are indicators of the weight or contribution of each original variable to the principal components. They can be interpreted as correlations between the original variables and the components, helping to understand the variables that have the most influence on each component.
            </p>
            <p>
                <strong>Score Plots:</strong> Score plots display the data in the space of the principal components, with each point representing a single process observation. They are used to observe clustering, trends, and outliers within the dataset, providing a visual summary of the process characteristics.
            </p>
            
        </div>
    )
})

// ################################################################################################################################

// data quality
documentation.push({

    "id": 800,
    "sectionId": "collections",
    "title": "Data Quality",
    "description": "Overview of Data Quality.",
    "keywords": ["data quality", "data quality standards", "DQS"],
    "content": (
        <div>
            <p>
                Bioprocess Intelligence provides a set of functionalities to help improve data quality in collection. First, it is possible to check whether variables, parameters, and metadata of processes in the collection satisfy certain conditions. Second, it is possible to ensure that variable, parameter, and metadata names are consistent. For instance, if the same variable is referred to by different names, it is possible to unify the naming of the variable. These functionalities can be implemented by defining Data Quality Standards (DQS) for the collection.
            </p>

            <h2>Data Quality Standards (DQS)</h2>
            <p>
                Data Quality Standards (DQS) are a set of rules that can be applied to a all processes in a collection to ensure that the data in the processes is of a certain quality. For example, a DQS can be defined that checks all processes in the collection for the existence of a certain variable. In addition to variables, DQS can check the existence parameters and metadata. Also, DQS can check whether the values of the variables, parameters, and metadata satisfy given requirements (e.g., whether the value of a variable is within a certain range).
            </p>
            <p>
                Types of DQS:
            </p>
            <p>
                <ul>
                    <li>Variable DQS</li>
                    <li>Parameter DQS</li>
                    <li>Metadata DQS</li>
                </ul>
            </p>
            <p>
                DQS must also be define for all variables before they are imported. In this way, only variables that meet the DQS will be imported into the processes of the collection.
            </p>

            <p></p>

            <h2>Importing Data and Data Quality</h2>
            
            <p>
                When importing data into a process, the data is checked against the DQS defined for the collection. If the data does not meet the DQS, the data will not be imported into the process. If a variable that is being imported does not have a corresponding DQS, the data will not be imported into the process.
            </p>

                
        </div>
    )
})


/* Support section with a link to our Jira Service Desk */
documentation.push({
    "id": 900,
    "sectionId": "general",
    "title": "Support",
    "description": "Support",
    "keywords": ["support"],
    "content": (
        <div>
            <p>
                If you have any questions or need support, please contact us via our <a href="https://wisdomengine.atlassian.net/servicedesk/customer/portal/1" target="_blank">Service Desk</a>.
            </p>
        </div>
    )
})

/*
documentation.push({
    "id": 10,
    "sectionId": "calculators",
    "title": "Differentiation calculator",
    "description": "",
    "keywords": ["differentiation", "calc_differentiation"],
    "content": (
        <div>
            <p>The differentiation calculator can be used to calculate the derivative of a variable with respect to time.</p>
                    
            <h3>Inputs:</h3>
            <ul>
                <li>The function to be differentiated</li>
                <li>The name of the output variable</li>
                <li>The required units of the output variable</li>
            </ul>

            <h3>Outputs:</h3>
            <ul>
                <li>The resulting output variable</li>
            </ul>
        </div>
    )
})


documentation.push({
    "id": 11,
    "sectionId": "calculators",
    "title": "Integration calculator",
    "description": "",
    "keywords": ["integration" , "calc_integration"],
    "content": (
        <div>
            <p>The integration calculator can be used to calculate the integral of a variable over time.</p>
            
            <h3>Inputs:</h3>
            <ul>
                <li>The function to be integrated</li>
                <li>The name of the output variable</li>
                <li>The required units of the output variable</li>
            </ul>


            <h3>Outputs:</h3> 
            <ul>
                <li>The resulting output variable</li>
            </ul>

        </div>
    )
})



documentation.push({
    "id": 13,
    "sectionId": "calculators",
    "title": "Evaporation calculator",
    "description": "",
    "keywords": ["evaporation", "calc_evaporation"],
    "content": (
        <div>
            <p>Characterization of evaporation is important for being able to perform mass balancing of a bioprocess. Therefore, this calculator has been designed to aid in the determination of the evaporation rate of water from the bioreactor.</p>

        </div>
    )
})

documentation.push({
    "id": 14,
    "sectionId": "calculators",
    "title": "Offgas calculator",
    "description": "",
    "keywords": ["offgas", "calc_offgas"],
    "content": (
        <div>
            <p>
            The Oxygen Uptake Rate (OTR) and Carbon Dioxide Evolution Rate (CER) are crucial parameters in the monitoring and control of fermentation processes. They are typically calculated from offgas measurements, i.e., the composition of the gas exiting the bioreactor. The offgas calculator can be used to calculate the OTR and CER from the offgas composition. 
            </p>
            <h3>Inputs:</h3>
            <p>
                <ul>
                    <li>Air in:</li>
                    <li>CO2 in</li>
                    <li>CO2 offgas</li>
                    <li>O2 in</li>
                    <li>O2 offgas</li>
                    <li>Mass</li>
                </ul>
            </p>

            <h3>Outputs:</h3>
            <p>
                <ul>
                    <li>rO2:</li>
                    <li>rO2_mass</li>
                    <li>rO2_ ...</li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </p>

        </div>
    )
})



// ################################################################################################################################

documentation.push({
    "id": 16,
    "sectionId": "media_manager",
    "title": "Introduction to Media Manager",
    "description": "",
    "keywords": ["media", "media manager"],
    "content": (
        <div>

        </div>
    )
})

*/


//export default documentation;
export {sections, documentation}