import React from "react";
import { useLocation } from "react-router-dom";

interface VideoEmbedderProps {
    videoId: string;
}

const VideoEmbedder = (props: VideoEmbedderProps) => {

  const location = useLocation();

  // Embed the video into a YouTube URL
 // src="https://share.synthesia.io/embeds/videos/43303810-8918-4fc8-a6eb-9da496f4c51f"
const videoUrl = `https://share.synthesia.io/embeds/videos/${props.videoId}`;

  return (
   
    <iframe src={videoUrl}
        loading="lazy" 
        title="" 
        //allowfullscreen 
        allow="encrypted-media; fullscreen;" 
        //style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"
        style = {{position: "absolute", width: "100%", height:"100%", top: 0, left: 0, border: "none", padding: 0, margin: 0, overflow:"hidden"}}
    >
    </iframe>        

  );
};

export default VideoEmbedder;
