import React, { useState, useEffect } from "react";

const LoadingStatus = ({ mode = "mix", funnyProbability = 0.4 }) => {
  const funnyMessages = [
    "Skimming abstracts because who reads full papers anyway...",
    "Searching databases and questioning why they’re so outdated...",
    "Opening tabs faster than they can be closed...",
    "Taking a coffee break to reflect on the futility of it all...",
    "Decoding jargon that was clearly invented to confuse people...",
    "Comparing patents and asking if this is really 'new'...",
    "Realizing half the search results are behind paywalls...",
    "Rolling eyes at exaggerated claims in academic papers...",
    "Laughing at the thought of calling this 'streamlined'...",
    "Sighing heavily at yet another irrelevant search result...",
    "Googling 'how to innovate without actually innovating'...",
    "Counting how many times 'novel' appears in one paper...",
    "Asking if anyone actually cited this patent on purpose...",
    "Checking if this invention will save humanity or doom it...",
    "Wondering if the patent was filed by a robot...",
    "Finding a research paper that cites itself 50 times...",
    "Taking bets on which patent claims are fluff...",
    "Noticing all patents look suspiciously similar...",
    "Realizing the bibliography is longer than the paper...",
    "Searching for 'how to get out of this research spiral'..."
  ];

  const notFunnyMessages = [
    "Tokenizing input query for semantic analysis...",
    "Embedding query into vector space for similarity search...",
    "Retrieving top-ranked documents from knowledge graph...",
    "Performing entity recognition on retrieved results...",
    "Generating context-aware summaries for literature...",
    "Cross-referencing results with patent ontologies...",
    "Calculating cosine similarity between vectorized texts...",
    "Applying transformer-based models for result ranking...",
    "Performing multi-hop reasoning over connected datasets...",
    "Normalizing text data to ensure consistent preprocessing...",
    "Clustering documents for topic modeling...",
    "Applying sentiment analysis to user-generated content...",
    "Extracting technical terms from abstract texts...",
    "Analyzing citation networks for influential papers...",
    "Optimizing query parameters for database search...",
    "Validating document classification accuracy...",
    "Scoring literature based on domain relevance...",
    "Parsing multi-lingual text for key insights...",
    "Performing time-series analysis on publication trends...",
    "Identifying gaps in the literature for new opportunities..."
  ];

  const getMessageByMode = () => {
    if (mode === "funny") return funnyMessages;
    if (mode === "notFunny") return notFunnyMessages;

    // For "mix" mode, determine based on probability
    const random = Math.random();
    return random < funnyProbability ? funnyMessages : notFunnyMessages;
  };

  const [currentStatus, setCurrentStatus] = useState("");
  const messages = getMessageByMode();

  useEffect(() => {
    const updateStatus = () => {
      const randomIndex = Math.floor(Math.random() * messages.length);
      setCurrentStatus(messages[randomIndex]);
    };

    updateStatus(); // Set an initial random message
    const intervalId = setInterval(updateStatus, 3000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [messages]);

  return (
    <div style={{ fontStyle: "italic", marginTop: "20px", textAlign: "left" }}>
      {currentStatus}
    </div>
  );
};

export default LoadingStatus;
