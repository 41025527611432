import { useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import { Layout, Menu, Divider} from 'antd';
import { ExperimentOutlined, TeamOutlined, BarsOutlined, CommentOutlined, CodeOutlined, BulbOutlined, DotChartOutlined, FolderOutlined, ImportOutlined, FileTextOutlined, AppstoreOutlined, BarChartOutlined, SettingOutlined , LineChartOutlined, FunctionOutlined , DatabaseOutlined , MailOutlined} from '@ant-design/icons';
import { useApolloClient, ApolloClient, NormalizedCacheObject  } from '@apollo/client';
import { createOptimisticResponse } from '../components/createOptimisticResponse';

//import logo_dark from '../images/bpi_logo_dark_2.png'
import logo_dark from '../images/bpi_logo_dark.png'
//import x_dark from '../images/bpi_dark_2.png'
import x_dark from '../images/bpi_dark.png'
import useWindowDimensions from './useWindowDimensions';

import { CORE_USER_PROFILE_FIELDS } from './fragments';

const { Sider } = Layout;

const GET_USER_PROFILE = gql`
    ${CORE_USER_PROFILE_FIELDS}
    query userProfile {  
        userProfile {
            ...CoreUserProfileFields
        }  
    }
`;

const UPDATE_USER_PROFILE = gql`
    ${CORE_USER_PROFILE_FIELDS}
    mutation updateUserProfile($input: UpdateUserProfileInputType!) {
        updateUserProfile( input:$input) {
            userProfile {
                ...CoreUserProfileFields
            }
        }
    }
`;


function SideMenu()  {

    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

    const { height, width } = useWindowDimensions();

    const [collapsed, setCollapsedState] = useState(false);
    const [leftMenuOpenKeys, setOpenKeysState] = useState(["1", "2"]);

    const location = useLocation();
    const { pathname } = location;

    const { loading:queryLoading, error:queryError, data:queryData, refetch} = useQuery(GET_USER_PROFILE);

    const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);

    
    // use Effect to set the collapsed and open keys state based on the query data
    // this is needed to set the initial state of the menu

    useEffect(() => {

        // fetch the user profile and wait for the data to load
        const fetchUserProfile = async () => {
            await refetch();
        }

        fetchUserProfile();



        if (queryData?.userProfile) {
            setCollapsedState(queryData.userProfile.leftMenuCollapsed);
            setOpenKeysState(queryData.userProfile.leftMenuOpenKeys);
        }

    }, [])
    

    

    // update the collapsed state
    function setCollapsed(collapsedLocal:boolean) {

        setCollapsedState(collapsedLocal); // update the state variable

        //let openKeys = queryData?.userProfile?.leftMenuOpenKeys;
        let openKeys = queryData?.userProfile?.leftMenuOpenKeys;

        if (collapsedLocal === false ) {

            if (openKeys == undefined) {
                openKeys = ["1", "2"];
            }
            
        } else {
            setOpenKeysState([]); // if the menu is collapsed, then update the open keys to an empty array
            openKeys = [];
        }

        // wait for the animation to finish before updating the open keys
        setTimeout(() => {
            setOpenKeysState(openKeys);
        }, 300);

        const optimisticResponse = createOptimisticResponse(
            client, 
            CORE_USER_PROFILE_FIELDS,
            queryData?.userProfile.id,
            { leftMenuCollapsed: collapsedLocal },
            'CoreUserProfileFields'
        );

        //setCollapsedState(collapsed); // update the local state
        
        updateUserProfile({ variables: { input: { leftMenuCollapsed: collapsedLocal } ,
            optimisticResponse: {
                __typename: "Mutation",
                updateUserProfile: {
                    __typename: "UpdateUserProfilePayload",
                    userProfile: {
                        __typename: "UserProfileType",
                        ...optimisticResponse
                    }
                }
            }
        } }); // update the database
        
    }

    // update the open keys state
    function setOpenKeys(openKeysLocal:any) {

        //console.log("openKeysLocal", openKeysLocal);

        setOpenKeysState(openKeysLocal); // update the local state

        if (!collapsed) { 

            const optimisticResponse = createOptimisticResponse(
            client, 
            CORE_USER_PROFILE_FIELDS,
            queryData?.userProfile.id,
            { leftMenuOpenKeys: openKeysLocal },
            'CoreUserProfileFields'
            );

            updateUserProfile({ variables: { input: { leftMenuOpenKeys: openKeysLocal } ,
                optimisticResponse: {
                    __typename: "Mutation",
                    updateUserProfile: {
                        __typename: "UpdateUserProfilePayload",
                        userProfile: {
                            __typename: "UserProfileType",
                            ...optimisticResponse
                        }
                    }
                }
            } }); // update the database
        }
        
    }

    
    // useEffect to set the collapsed state based on window width
    useEffect(() => {
        if (width < 1200) {
            setCollapsed(true)
        } 
    }, [width])
    

    if (queryError) {
        return (<p>Error : unable to get the user profile</p>)
    }

    return (
        <div style={{background:'#001229'}}>

            <Sider 
                collapsible
                width={220}
                
                collapsed={collapsed}
                onCollapse={() => setCollapsed(!collapsed)}
                style={{ 
                    overflow: 'auto',
                    height: '100vh',
                }}
                >
                
                { !collapsed &&
                    <div className="logo"> <img alt="" src={logo_dark} style={{height:65, marginLeft:10, marginTop:10, marginBottom:20}}/> </div>
                }

                { collapsed && 
                    <div className="logo"> <img alt="Bioprocess Intelligence" src={x_dark} style={{height:65, marginLeft:10, marginTop:10, marginBottom:20}}/> </div>
                }

                <Menu mode="inline"  theme="dark" selectedKeys={[pathname]} openKeys={ leftMenuOpenKeys }   onOpenChange={setOpenKeys}> 
                    
                    {/*
                    <Menu.Item key="/dashboard" icon={<DashboardOutlined />} >
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                    */}

                    {/*
                    <Menu.SubMenu title="Planning" icon={<DatabaseOutlined />} >
                        
                        <Menu.Item key="/teams" icon={<FolderOutlined />} >
                            <Link to="/teams">Plan process</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    */}
                                     
                    <Menu.SubMenu title="Team" icon={<TeamOutlined />} key="1" data-testid="team-menu">
                        
                        {/*
                        <Menu.Item key="/processes" icon={<ExperimentOutlined    />} >
                            <Link to="/processes">Processes</Link>
                        </Menu.Item>
                        */}
                        
                        
                        <Menu.Item key="/collections" icon={<FolderOutlined />} data-testid="collections-menu">
                            <Link to="/collections" >Collections</Link>
                        </Menu.Item>
                        

                        <Menu.Item key="/import" icon={<ImportOutlined />}data-testid="import-menu">
                            <Link to="/import">Import</Link>   
                        </Menu.Item>

                        <Menu.Item key="/media" icon={<ExperimentOutlined />} data-testid="media-menu">
                            <Link to="/media">Media</Link>
                        </Menu.Item>

                        <Menu.Item key="/calculators" icon={<FunctionOutlined />} data-testid="calculators-menu">
                            <Link to="/calculators">Calculations</Link>
                        </Menu.Item>

                        <Menu.Item key="/lexicon" icon={<BarsOutlined />} data-testid="lexicon-menu">
                            <Link to="/lexicon">Lexicon</Link>
                        </Menu.Item>

                        { !queryLoading && (queryData?.userProfile?.admin || queryData?.userProfile?.selectedTeam?.owner?.id === queryData?.userProfile?.user?.id) &&
                        <Menu.Item key="/settings" icon={<SettingOutlined />} data-testid="settings-menu">
                            <Link to="/settings">Settings</Link>
                        </Menu.Item>
                        }
                        
                        
                    </Menu.SubMenu>

                    
                    
                    {/*
                    <Menu.Item key="/design" icon={<BulbOutlined />} >
                        <Link to="/design">DoE</Link>
                    </Menu.Item>
                    */}

                    
                    
                    

                    <Menu.SubMenu title="Analysis" icon={< BulbOutlined/>} key="2" data-testid="analysis-menu">

                        {/*<Menu.Item key="/analyses" icon={<AppstoreOutlined />} >
                            <Link to="/analyses">Analyses</Link>
                        </Menu.Item>
                        */}

                        {/*
                        <Menu.Item key="/calculators" icon={<FunctionOutlined />} >
                            <Link to="/calculators">Data Quality</Link>
                        </Menu.Item>
                        */}


                        <Menu.Item key="/process-explorer" icon={<LineChartOutlined />} data-testid="process-explorer-menu">
                            <Link to="/process-explorer">Process Explorer</Link>
                        </Menu.Item>

                        <Menu.Item key="/kpis" icon={<BarChartOutlined />} data-testid="kpis-menu">
                            <Link to="/kpis">KPIs</Link>
                        </Menu.Item>

                        <Menu.Item key="/kpi-analysis" icon={<BarChartOutlined />} data-testid="kpi-analysis-menu">
                            <Link to="/kpi-analysis">KPI Analysis</Link>
                        </Menu.Item>
                        
                        {/*
                        <Menu.Item key="/optimization" icon={<BarChartOutlined />} data-testid="optimization">
                            <Link to="/optimization">Optimization</Link>
                        </Menu.Item>
                        */}

                        <Menu.Item key="/modeling" icon={<DotChartOutlined />} data-testid="modeling-menu">
                            <Link to="/modeling"  >Modeling</Link>
                        </Menu.Item>

                        {/*
                        <Menu.Item key="/notebook" icon={<CodeOutlined />} >
                            <Link to="/notebook">Code notebook</Link>
                        </Menu.Item>
                        */}

                        {/*
                        <Menu.Item key="/report" icon={<FileTextOutlined />}>
                            <Link to="/report"  >Reports</Link>
                        </Menu.Item>
                        */}
                    
                    </Menu.SubMenu>

                    { /* AI with a beta tag: "beta" should be red and superscripted */ }
                    
                    { !queryLoading && queryData.userProfile.organization.aiAssistantEnabled &&
                        <Menu.Item key="/ai" icon={<CommentOutlined />} data-testid="ai-menu">
                            <Link to="/ai">AI Assistant <sup style={{color: "red"}}>beta</sup></Link>
                        </Menu.Item>
                    }   
                    

                    { !queryLoading && queryData.userProfile.admin &&
                        <Divider />
                    }
                    


                    {/*
                    <Menu.Item key="/support" icon={<MailOutlined />} disabled={false}>
                        <Link to="/support">Support & Feedback</Link>
                    </Menu.Item>                    
                    */}
                    

                    { !queryLoading && queryData.userProfile.admin &&
                        <Menu.Item key="/admin" icon={<SettingOutlined />} data-testid="admin-menu">
                            <Link to="/admin">Admin Center</Link>
                        </Menu.Item>
                    }
                   
                    {/*
                    <Menu.Item key="/community" icon={<ShareAltOutlined />} >
                        <Link to="/community">Community</Link>
                    </Menu.Item>  
                    */}

                    
                </Menu>
            </Sider>
        </div>

    )   
}

export default SideMenu;