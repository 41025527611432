import  {useState, useEffect, useMemo} from "react";
import { useQuery, useLazyQuery, useMutation, gql } from '@apollo/client';
import { UserOutlined, RobotOutlined , DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SearchOutlined, PlusOutlined , EllipsisOutlined } from '@ant-design/icons';
import HeaderExtra from "../components/HeaderExtra";
import { Skeleton , Card, Avatar, Button, Space, Input, message , Popconfirm, Tooltip, Popover}  from 'antd';
import PageHeader from "../components/PageHeader";
import { pollInterval } from "..";
import ReactMarkdown from 'react-markdown';
import LoadingStatus from "../components/ai/LoadingStatus";

const  { TextArea } = Input;

const GET_CHATS = gql`
    query chats {
        chats {
            id
            title
            references
            creationDate
        }
        userProfile {
            id
            darkMode
            organization {
                id
                aiAssistantEnabled
            }
        }
    }
    
`;

const GET_CHAT = gql`
    query chat($id: ID!) {
        chat(id: $id) {
            id
            title
            references
            messageSet {
                id
                message
                userType
                timestamp
                reviewer
            }
        }
    }
`;

const ADD_MESSAGE_TO_CHAT = gql`
mutation addMessageToChat($input: AddMessageToChatInputType!) {
    addMessageToChat(input: $input) {
        chat {
            id
            title
            references
            messageSet {
                id
                message
                timestamp
                userType
                reviewer
            }
        }
    }
}
`;

const DELETE_CHAT = gql`
mutation deleteChat($id: ID!) {
    deleteChat(id: $id) {
        ok
    }
}
`;
                

function AI() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });

    //const { height, width } = useWindowDimensions();
    const [selectedChat, setSelectedChat] = useState(0);
    const [userMessage, setUserMessage] = useState("");
    
    const { loading:queryLoading, error:queryError, data:queryData } = useQuery(GET_CHATS);

    // use a lazy query to get the chat messages
    const [getChat, { loading:queryChatLoading, error:queryChatError, data:queryChatData }] = useLazyQuery(GET_CHAT, { pollInterval: pollInterval });

    // mutation to add a message to a chat
    const [addMessageToChatMutation, { loading:mutationLoading, error:mutationError, data:mutationData }] = useMutation(ADD_MESSAGE_TO_CHAT, 
        {
            // refetch
            refetchQueries: [
                { query: GET_CHATS },
            ],
        }
    );

    // mutation to delete a chat
    const [deleteChatMutation, { loading:deleteMutationLoading, error:deleteMutationError, data:deleteMutationData }] = useMutation(DELETE_CHAT,
        {
            // refetch
            refetchQueries: [
                { query: GET_CHATS },
            ],
            onCompleted: () => {
                setSelectedChat(0);
            }
        }, 
        
    );

    // use effect to get the chat messages when the selected chat changes
    useEffect(() => {
        if (selectedChat === 0) {
            return
        }
        getChat({ variables: { id: selectedChat } });
    }, [selectedChat]);

    // use effect to scroll to the bottom of the chat when the messages change
    useEffect(() => {
        if (selectedChat === 0) {
            return
        }
        var element = document.getElementById("2-1");
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }, [queryChatData, mutationLoading]);

    // use effect to select the first chat when a new chat is added
    useEffect(() => {
        if (queryData && queryData.chats.length > 0) {
            setSelectedChat(queryData.chats[0].id);
        }
    }, [queryData]);

    const createReferenceMapping = (references?: string): Record<string, number> => {
        const referenceMap: Record<string, number> = {};
    
        if (references) {
            const referenceLines = references.split("\n\n");
    
            referenceLines.forEach((line, index) => {
                const pubIdMatch = line.match(/pub_id=(\d+)/);
                if (pubIdMatch) {
                    const pubId = pubIdMatch[1];
                    referenceMap[pubId] = index + 1;
                }
            });
        }
    
        return referenceMap;
    };
    
    const aIChatBackground = useMemo(() => {
        return queryData?.userProfile.darkMode ? "#1a1a1a" : "#f9f9f9";
    }, [queryData?.userProfile.darkMode]);

    //const referenceMapping = queryChatData?.chat?.references ? createReferenceMapping(queryChatData.chat.references) : {};
    // use memo for referenecMapping
    const referenceMapping = useMemo(() => {
        if (!queryChatData?.chat?.references) {
            return {}; // Return an empty object if references are unavailable
        }
        return createReferenceMapping(queryChatData.chat.references);
    }, [queryChatData?.chat?.references]);

    const formattedReferences = useMemo(() => {
        if (!queryChatData?.chat?.references || !referenceMapping) {
            return ''; // Return an empty string if references or referenceMapping are unavailable
        }
    
        const referenceLines = queryChatData.chat.references.split("\n\n");
    
        return referenceLines
            .map((line:any) =>
                line.replace(/pub_id=(\d+)/, (match:any, pubId:any) => {
                    return `${referenceMapping[pubId]}.`;
                }
            ))
            .join('\n\n'); // Join all references into a single string
    }, [queryChatData?.chat?.references, referenceMapping]);

    const replacePubIdsWithNumbers = (message?: string, referenceMap?: Record<string, number>): string => {
        if (!message || !referenceMap) {
            return message || '';  // Return the original message or an empty string if unavailable
        }
    
        return message.replace(/(\[)?pub_id=(\d+)(\])?/g, (_, openBracket, pubId) => {
            const referenceNumber = referenceMap[pubId];
            // Always return the reference number enclosed in brackets, regardless of original format
            if (referenceNumber !== undefined) {
                return `[${referenceNumber}]`;
            } else {
                // If not found in the referenceMap, return the original match enclosed in brackets
                return `[pub_id=${pubId}]`;
            }
        });
    };

    // function to replace numbers e.g. [2] with references e.g. [pub_id=102]
    const replaceNumbersWithPubIds = (message?: string, referenceMap?: Record<string, number>): string => {
        if (!message || !referenceMap) {
            return message || '';  // Return the original message or an empty string if unavailable
        }
    
        return message.replace(/\[(\d+)\]/g, (_, referenceNumber) => {
            const pubId = Object.entries(referenceMap).find(([_, number]) => number === parseInt(referenceNumber))?.[0];
            return pubId !== undefined ? `[pub_id=${pubId}]` : `[${referenceNumber}]`;
        });
    }
    
    const formattedMessages = useMemo(() => {
        if (
          selectedChat !== 0 &&
          queryChatData &&
          queryChatData.chat &&
          queryChatData.chat.messageSet.length > 0
        ) {
          return queryChatData.chat.messageSet.map((message: any) => (
            <div key={message.id}>
              {message.userType === 'human' && (
                <div style={{ padding: 10, marginBottom: 5 }} id={message.id}>
                  <Space direction="horizontal">
                    <Avatar icon={<UserOutlined />} />
                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                      <ReactMarkdown>
                        {replacePubIdsWithNumbers(message.message, referenceMapping)}
                      </ReactMarkdown>
                    </div>
                  </Space>
                </div>
              )}
              {message.userType === 'ai' && (
                <div
                  style={{
                    padding: 10,
                    marginBottom: 5,
                    backgroundColor: aIChatBackground,
                  }}
                  id={message.id}
                >
                  <Space direction="horizontal">
                    <Avatar icon={<RobotOutlined />} />
                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                      <ReactMarkdown>
                        {replacePubIdsWithNumbers(message.message, referenceMapping)}
                      </ReactMarkdown>
                      {message.reviewer && (() => {
                        const reviewerData = JSON.parse(message.reviewer);
                        return reviewerData && reviewerData.overall_response ? (
                          <>
                            <Space>
                              {/* Accuracy Icon */}
                              <Tooltip
                                title={replacePubIdsWithNumbers(
                                  reviewerData.evaluation_criteria.accuracy.justification,
                                  referenceMapping
                                )}
                              >
                                {reviewerData.evaluation_criteria.accuracy.rating ===
                                'Approved' ? (
                                  <CheckCircleOutlined
                                    style={{ color: 'green', fontSize: '16px' }}
                                  />
                                ) : (
                                  <CloseCircleOutlined
                                    style={{ color: 'red', fontSize: '16px' }}
                                  />
                                )}
                              </Tooltip>
      
                              {/* Relevance Icon */}
                              <Tooltip
                                title={replacePubIdsWithNumbers(
                                  reviewerData.evaluation_criteria.relevance.justification,
                                  referenceMapping
                                )}
                              >
                                {reviewerData.evaluation_criteria.relevance.rating ===
                                'Approved' ? (
                                  <CheckCircleOutlined
                                    style={{ color: 'green', fontSize: '16px' }}
                                  />
                                ) : (
                                  <CloseCircleOutlined
                                    style={{ color: 'red', fontSize: '16px' }}
                                  />
                                )}
                              </Tooltip>
      
                              {/* Supported by References Icon */}
                              <Tooltip
                                title={replacePubIdsWithNumbers(
                                  reviewerData.evaluation_criteria.supported_by_references.justification,
                                  referenceMapping
                                )}
                              >
                                {reviewerData.evaluation_criteria.supported_by_references
                                  .rating === 'Approved' ? (
                                  <CheckCircleOutlined
                                    style={{ color: 'green', fontSize: '16px' }}
                                  />
                                ) : (
                                  <CloseCircleOutlined
                                    style={{ color: 'red', fontSize: '16px' }}
                                  />
                                )}
                              </Tooltip>
                            </Space>
                            
                            {/*
                            <p style={{ color: 'grey', fontSize: '12px' }}>
                              <strong>Justification:</strong>{' '}
                              {replacePubIdsWithNumbers(
                                reviewerData.overall_response.justification,
                                referenceMapping
                              )}
                            </p>
                            */}

                          </>
                        ) : null;
                      })()}
                    </div>
                  </Space>
                </div>
              )}
            </div>
          ));
        } else {
          return null;
        }
      }, [
        selectedChat,
        queryChatData,
        aIChatBackground,
        referenceMapping,
      ]);
      
    function onChangeSelectedChat(id: any) {
        setSelectedChat(id);
    }

    if (queryError) {
            message.error("Unable to get the chats")
    }

    if (queryLoading) {
        return (
            <Skeleton active />
        )
    }
  
    function onSubmitMessage() {
        if (selectedChat === 0) {
            addMessageToChatMutation({ variables: { input: { message: replaceNumbersWithPubIds(userMessage, referenceMapping) } } });
        } else {
            addMessageToChatMutation({ variables: { input: { chatId: selectedChat, message: replaceNumbersWithPubIds(userMessage, referenceMapping) } } });
        }
        setUserMessage("");
    }

    interface Reference {
        pub_id: string;
        text: string;
    }
    

    
    
    /*
    const renderReferences = (references?: string, referenceMap?: Record<string, number>): string => {
        if (!references || !referenceMap) {
            return '';  // Return an empty string if references or referenceMap are unavailable
        }
    
        const referenceLines = references.split("\n\n");
    
        const formattedReferences = referenceLines.map((line, index) => {
            return line.replace(/pub_id=(\d+)/, (match, pubId) => {
                return `${referenceMap[pubId]}.`;
            });
        });
    
        return formattedReferences.join('\n\n');  // Join all references into a single string
    };*/

    // use memo to avoid re-rendering the references renderReferences(queryChatData.chat.references, referenceMapping)
    

   
    /*
    let test_data = "Additionally, pH levels can range from 3 to 10, but values closer to 6 are most commonly recommended for lipid biosynthesis [pub_id=102]. The optimal temperature for lipid biosynthesis is 30°C [pub_id=103].";
    let test_references = "pub_id=102: Smith et al. 2020\n\npub_id=103: Johnson et al. 2021";
    let test_reference_mapping = createReferenceMapping(test_references);
    let test_result = replacePubIdsWithNumbers(test_data, test_reference_mapping);
    let test_result2 = renderReferences(test_references, test_reference_mapping);
    console.log(test_result);
    console.log(test_result2);
    */

    // search, new chat icons

    const chatHeader = (
        <div>
            {/*<Button type="text" icon={<SearchOutlined />} />*/}
            <Button type="text" icon={<PlusOutlined />} data-testid="new-chat-button" onClick={() => { setSelectedChat(0) }} />
        </div>
    );

    const selectedBackgroundColor = queryData?.userProfile.darkMode ? '#333333' : '#e6f7ff';
    const selectedBorderColor = queryData?.userProfile.darkMode ? '#555555' : '#1890ff';

    // if aiAssistantEnabled is false, display a message
    if (queryData && queryData?.userProfile.organization.aiAssistantEnabled === false) {
        return (
            <div>
                <PageHeader
                    //onBack={() => window.history.back()}
                    title="AI Research Assistant"
                    extra={<HeaderExtra />}
                />
                <div style={{ padding: 20 }}>
                    <Card style={{ height: 'calc(100vh - 125px)' }} size="small">
                        <p>
                            The AI Research Assistant is currently disabled. Please contact your organization's administrator to enable it.
                        </p>
                    </Card>
                </div>
            </div>
        );
    }

    return(  

        <div>
            
            <PageHeader
                //onBack={() => window.history.back()}
                title="AI Research Assistant"
                extra={ <HeaderExtra/> }
            />

            <div style={{ display:'flex' }}>

                
                <div style = {{ flex:'0 0 220px' }} id="1">

                    <div style={{height: 'calc(100vh - 125px)'}}>
                        <Card title="Chats" style={{ marginRight:20 , marginBottom:10, height:'calc(100vh - 125px)' }} size="small" extra={chatHeader}>
                            {/* Button to create a new chat */}

                            {/*
                            <Button type="text" style={{ marginBottom:10 , width: "100%" }} onClick={() => { setSelectedChat(0) }} data-testid="new-chat-button">New Chat</Button>
                            */}

                            {/* List of chats */}
                            
                            <div style={{ overflow: "scroll", height: 'calc(100vh - 250px)' }}>
                            {queryData.chats.length > 0 && queryData.chats.map((chat: any) => (
                                <Tooltip title={chat.title} key={chat.id} placement="right">
                                <Card
                                    id={chat.id}
                                    size="small"
                                    hoverable
                                    onClick={() => onChangeSelectedChat(chat.id)}
                                    style={{
                                    marginBottom: 5,
                                    marginRight: 10,
                                    backgroundColor: chat.id === selectedChat ? selectedBackgroundColor : undefined,
                                    borderColor: chat.id === selectedChat ? selectedBorderColor : undefined,
                                    }}
                                >
                                    <Space direction="horizontal">
                                    <div style={{ width: "180px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                        {chat.title}
                                    </div>
                                    <Popover
                                        placement="bottomLeft"
                                        content={
                                        <Card size="small" bordered={false}>
                                            <Space direction="vertical">
                                            <Popconfirm
                                                title="Delete?"
                                                onConfirm={() => deleteChatMutation({ variables: { id: chat.id } })}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button type="text" data-testid={`delete-chat-button-${chat.id}`} style={{ width: "100%" }}>
                                                Delete
                                                </Button>
                                            </Popconfirm>
                                            {/* Uncomment and implement Edit functionality if needed */}
                                            {/* <Button type="text" data-testid={`edit-chat-button-${chat.id}`} style={{ width: "100%" }}>Edit</Button> */}
                                            </Space>
                                        </Card>
                                        }
                                    >
                                        <Button type="text" shape="default" icon={<EllipsisOutlined />} style={{ float: 'right', color: "#ccc" }} />
                                    </Popover>
                                    </Space>
                                </Card>
                                </Tooltip>
                            ))}
                            </div>



                        </Card>

                    </div>
                    
                </div>

                <div style={{ overflow:'auto' , width:"100%"}} id="2">

                    <Card  style={{ marginRight:20 , height:'calc(100vh - 125px)'  }} size="small">

                        {/* scroll to move to the end of the div when the user clicks on a chat */}
                        <div style={{ height: 'calc(100vh - 330px)', overflow: "scroll" }} id="2-1">
                            
                            {selectedChat !== 0 && queryChatData && queryChatData.chat && queryChatData.chat.messageSet.length > 0 &&
                                formattedMessages
                            }

                            { /* if there are no messages in the chat or there are no conversations, display a message */}
                            {selectedChat ===0 &&
                                <div style={{ padding:10, margin:20}}>
                                    <p style={{color:"grey"}}>Start a conversation by sending a message to the assistant.</p>
                                    <p style={{color:"grey"}}>The assistant will respond with information based on the latest research in the area of fermentation and bioprocess engineering.</p>
                                    <p style={{color:"grey"}}>It may take up to a minute for the assistant to respond.</p>
                                </div>
                            }

                                
                                {mutationLoading && 
                                    <div style={{ padding:10, margin:20}}>
                                        <LoadingStatus />
                                        <Skeleton active />
                                    </div>
                                }


                            

                        </div>

                        <div style={{ display:'flex' , flexDirection:'row' , justifyContent:'flex-start' , alignItems:'center' , marginTop:30, marginRight:30 }} id="2-2">
                                    
                                    {/*<TextArea rows={2} placeholder="Type a message" onChange={(e) => setUserMessage(e.target.value)} value={userMessage} onPressEnter={onSubmitMessage} data-testid="input-message"/>*/}
                                    <TextArea
                                        //rows={2}
                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                        placeholder="Type a message"
                                        onPressEnter={onSubmitMessage}
                                        onChange={(e) => setUserMessage(e.target.value)}
                                        value={userMessage}
                                        data-testid="input-message"
                                    />
                                    {/* disable the button if the textarea is empty */}
                                    <Button type="primary" style={{ marginLeft:10, marginTop:30 }} onClick={onSubmitMessage} data-testid="send-button">Send</Button>
                        </div>
                        

                        

                        <div id="2-3">
                            
                                <p style={{color:"grey"}}>
                                    The assistant may produce inaccurate information about data, people, places, or facts. Use with caution.
                                    <br/>Please do not share personal or confidential information with the assistant.
                                </p>
                                
                            
                        </div>


                        
                    </Card>

                </div>

                <div style={{ flex:'0 0 350px' }} id="3">
                    <Card title="References" style={{ height:'calc(100vh - 125px)' }} size="small">
                    <div style={{ height: 'calc(100vh - 250px)', overflow: "scroll" }} id="2-3">
                        {/* references is a text field */}
                        <ReactMarkdown>
                            {queryChatData && queryChatData.chat && queryChatData.chat.id === selectedChat && 
                                formattedReferences
                            }
                        </ReactMarkdown>
                    </div>
                    </Card>
                </div>


            </div>

        </div>
        
    )

}

export default AI;