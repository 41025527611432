import moment from 'moment';
import { Card, Button, Form, Input, Tabs, Skeleton, message, Progress, List} from 'antd';
import MomentDatePicker from '../components/MomentDatePicker';
import PageHeader from '../components/PageHeader';
import {Link, useParams} from "react-router-dom";
import { useQuery, useMutation, gql } from '@apollo/client';
import {CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
import MetaDataTable from "../components/MetaDataTable";
import ParameterTable from "../components/ParameterTable";
import NoteTable from '../components/NoteTable';
import VariableTable from "../components/variable/VariableTable";
import HeaderExtra from "../components/HeaderExtra";
import useWindowDimensions from "../components/useWindowDimensions";
import { EXTENDED_PROCESS_FIELDS } from '../components/fragments';

const { TextArea } = Input;
const { TabPane } = Tabs;


const GET_PROCESS = gql`
    ${EXTENDED_PROCESS_FIELDS}
    query process ($id:ID!) {  
        process(id:$id) {
            ...ExtendedProcessFields
        }
    }
`;

const UPDATE_PROCESS = gql`
    ${EXTENDED_PROCESS_FIELDS}
    mutation UpdateProcess($input: UpdateProcessInputType!) {
        updateProcess( input:$input ) {
            process {
                ...ExtendedProcessFields
            }
        }
    }
`;

const GET_VERSIONS = gql`
query Versions ($processId:ID!) {  
        versionsByProcessId (processId:$processId) {
            id
            revision {
                id
                dateCreated
                comment
                user {
                    username
                }
            }
        }
    }
`;

function Process() {

    //ReactGA.initialize('UA-219470712-1', { testMode: process.env.NODE_ENV === 'test' });

    // get the url parameters from react router
    let params:any = useParams();

    // window dimensions
    const { height, width } = useWindowDimensions();

    // apollo hooks
    const { loading, error, data } = useQuery(GET_PROCESS , {
        variables:{id:params.id},
        fetchPolicy: "cache-and-network",
        //pollInterval: pollInterval,
    });

    // lazyQuery for getting the process on demand
    //const [getProcess, { loading, error, data }] = useLazyQuery(GET_PROCESS, {variables:{id:params.id}});



    //ReactGA.pageview('/processes/process/' + params.id );
    
    const [updateProcess] = useMutation( UPDATE_PROCESS ,{
        refetchQueries: [ { query: GET_VERSIONS, variables: { processId: params.id } } ], 
        onCompleted(data) { message.success( "Process was updated successfully.") } ,
        onError(error) {message.error(error.message)},
    });
    

    const onFinish = (values: any) => {

        // convert start and end time to ISO string
        let startTime = undefined
        let endTime = undefined
        if (values['startTime'] != undefined) {
            startTime = values['startTime'].toISOString();
        }
        
        /*if (values['endTime'] != undefined) {
            endTime = values['endTime'].toISOString();
        }*/
        
        
        let processData = {
            id:params.id,
            name : values['name'], 
            description : values['description'],
            startTime : startTime,
            //endTime : endTime,
        };

        //console.log(processData)
        
        //console.log(processData)
        updateProcess( { variables: { input: processData  } } );
    };

    /* ************************************************************* */

    if (error) return ( 
        <Card>Error: {error.message} </Card>
    );

    if (!data && loading) return (
        <Card><Skeleton active /></Card>
    );

    // process info tab
    const formLayout = {
        labelCol: { span: 3 },

    };

    const tailLayout = {
        wrapperCol: { offset: 3, span: 12 },
    };

    /* ************************************************************* */
    // table layouts
    
    const variableTableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: false,
            width: '20%',
            render: (text:String, record:any) => (
                <Link to={'/processes/process/' +  params.id  + '/variable/'+ record.id } >{text}</Link>),
        },
        {
            title: 'Units',
            dataIndex: 'units',
            editable: false,
        },
        {
            title: 'Type',
            dataIndex: 'vartype',
            editable: false,
        }
    ]

    {/* ******************************************************************************** */}
    {/* ******************************************************************************** */}

    const routes = [
        {
            path: 'collections',
            breadcrumbName: 'Team: ' + data.process.topic.team.name,
        },
        {
            path:'collections/collection/' + data.process.topic.id,
            breadcrumbName: 'Collection: ' + data.process.topic.name,
        },
        {
          path: '',
          breadcrumbName:  'Process: ' + data.process.name,
        }
      ];

    /*function onClickRecalculate() {
        // lazy query to get the process
        getProcess();
    }*/

    return(  

        <div>

            <PageHeader
                //ghost={false}
                //onBack={() => window.history.back()}
                title={"Process: " + data.process.name} 
                //subTitle={ data.process.description }
                breadcrumb={{routes}}
                extra={ <HeaderExtra/> }
                //backIcon={false}
                //style={{ marginBottom:'10px' }}
            />
            
            
            <Card style={{minHeight:height-155}}>
                <Tabs destroyInactiveTabPane={true} defaultActiveKey="1" type="card" size="middle" >

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    <TabPane tab="Process information" key="1" data-testid="process-info-tab" destroyInactiveTabPane={true}>
                        <div style={{height:height-280 , overflow:"auto"}}>       
                            <Card >
                        
                                <Form 
                                    {...formLayout} 
                                    onFinish={onFinish}
                                    initialValues={{ 
                                        name: data.process.name, 
                                        description: data.process.description,
                                        startTime: data.process.startTime != null ?  moment(data.process.startTime) : undefined ,
                                        endTime: data.process.endTime != null ?  moment(data.process.endTime) : undefined ,
                                    }}
                                >

                                    <Form.Item label="Name:" name="name" rules={[{ required: true }]}>
                                        <Input maxLength={200} data-testid="process-name-input"/>
                                    </Form.Item>


                                    <Form.Item label="Description:" name="description">
                                        <TextArea 
                                            rows={4} 
                                            showCount
                                            maxLength={10000}
                                            data-testid="process-description-input"
                                        />
                                    </Form.Item>

                                    <Form.Item 
                                        label="Start time:"
                                        name="startTime"
                                        rules={[{ required: true }]} 
                                    >
                                        <MomentDatePicker
                                            showTime = {true}
                                            data-testid="startTime"
                                        />

                                    </Form.Item>
                                    
                                    {/*
                                    <Form.Item 
                                        label="End time:"
                                        name="endTime"
                                        rules={[{ required: false }]} 
                                    >
                                        <MomentDatePicker
                                            showTime = {true}
                                        />

                                    </Form.Item>
                                    */}

                                    {/* form item for last updated time which should be non editable and formatted to the browser timezone*/}

                                    <Form.Item label="Last updated:" >
                                        {moment(data.process.lastUpdated).format('YYYY-MM-DD HH:mm:ss')}
                                    </Form.Item>

                                    <Form.Item {...tailLayout}>
                                        <Button 
                                            type="primary" 
                                            htmlType="submit"
                                            disabled={data.process.topic.userPermissions=="read"}
                                            title = {data.process.topic.userPermissions=="read" ? "You do not have permission to edit this process" : ""}
                                            data-testid="save-button"
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>            
                        
                                </Form>
                            </Card>
                        </div>
                        
                        <br/>
                        
                    
                    </TabPane>

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    
                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    <TabPane tab="Metadata" key="3" data-testid="metadata-tab">
                        <div>
                            <MetaDataTable processId= {params.id} />
                        </div>

                    </TabPane>

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    <TabPane tab="Parameters" key="4" data-testid="parameters-tab">
                        <div style={{height:height-280 , overflow:"auto"}}>
                            <ParameterTable processId= {params.id} />
                        </div>
                    </TabPane>

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    <TabPane tab="Variables" key="5" data-testid="variables-tab">
                        <div >
                            <VariableTable processId= {params.id} />
                        </div>
                    </TabPane>

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    <TabPane tab="Notes" key="6" data-testid="notes-tab">
                        <div style={{height:height-280 , overflow:"auto"}}>
                            <NoteTable processId= {params.id} />
                        </div>
                    </TabPane>

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}
                    
                    {/*
                    <TabPane tab="Calculators" key="7"> 
                        <div style={{height:height-280 , overflow:"auto"}}>
                            <CalculatorTab processId= {params.id} />
                        </div>
                    </TabPane>
                    */}

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    <TabPane tab="Data Quality" key="2" data-testid="data-quality-tab">
                        <div style={{height:height-280 , overflow:"auto"}}>
                            <Card style={{marginTop:'10px'}}>
                                {/* Button to recalculate quality score */}
                                {/*<Button  style={{marginBottom:'10px'}} >Refresh</Button>*/}
                                <Progress percent={data.process.qualityScore} size="small" /> 
                                <div style={{marginTop:30}}>
                                    {data.process.qualityScore < 100 &&
                                        <List 
                                            header={<div>Data Quality Issues</div>}
                                            bordered
                                            dataSource={data.process.qualityMessages}
                                            renderItem={(item:any) => (
                                                <List.Item>
                                                    <CloseCircleOutlined style={{color:'red', marginRight:10}} />
                                                    {item}
                                                </List.Item>
                                            )}
                                        />
                                        }
                                        {data.process.qualityScore == 100 &&
                                            <div style={{textAlign:'center'}}>
                                                <CheckCircleOutlined style={{color:'green', fontSize:50}} />
                                                <div style={{fontSize:20}}>All data quality standards are met</div>
                                            </div>
                                        }

                                </div>
                            </Card>
                        </div>
                    </TabPane>

                    {/* ******************************************************************************** */}
                    {/* ******************************************************************************** */}

                    
                    {/*

                    {/*}
                    <TabPane tab="Import data" key="6">
                        <Card title="Add data to the process from Excel">
                                <ImportExcel processId= {params.id}/>
                        </Card>
                    </TabPane>
                    */}

                    {/*
                    <TabPane tab="History" key="7"> 
                        <div style={{height:height-280 , overflow:"auto"}}>
                            <HistoryTable processId= {params.id} />
                        </div>
                    </TabPane>
                    */}

                </Tabs>
            </Card>

        </div>
    )

}

export default Process;